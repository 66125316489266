import type { FC } from 'react';
import { useState } from 'react';

import NoticeRibbon, { NoticeRibbonType } from '../../../common/components/atoms/NoticeRibbon';
import RawHtml from '../../../common/components/atoms/RawHtml';

interface USPNoticeProps {
    text: string;
}

/**
 * Unique Selling Point notice bar
 */
const USPNotice: FC<React.PropsWithChildren<USPNoticeProps>> = (props) => {
    const [show, setShow] = useState(true);

    const close = () => {
        setShow(false);
    };

    if (!props.text) {
        return null;
    }

    return (
        <NoticeRibbon
            animated={false}
            show={show}
            type={NoticeRibbonType.info}
            handleCloseClick={close}
        >
            <RawHtml html={props.text} />
        </NoticeRibbon>
    );
};

export default USPNotice;
