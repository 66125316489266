import type { Dispatch, FC, Reducer } from 'react';
import { createContext, useCallback, useContext, useReducer } from 'react';

import { logMissingContext } from '../../../utility/logging';

interface BlockingContext {
    outsideClickBlocked: boolean;
    backBlocked: boolean;
    closeBlocked: boolean;
}

interface StateContextType {
    block: BlockingContext;
    triedToBack: boolean;
    triedToClose: boolean;
    triedToOutsideClick: boolean;
}

const StateContext = createContext<StateContextType | null>(null);

const DispatchContext = createContext<Dispatch<DispatchAction> | null>(null);

type DispatchAction =
    | { type: 'setTriedToClose'; payload: boolean }
    | { type: 'setTriedToBack'; payload: boolean }
    | { type: 'setTriedToOutsideClick'; payload: boolean }
    | { type: 'setBlockingContext'; payload: BlockingContext }
    | { type: 'blockAll' }
    | { type: 'unblockAll' }
    | { type: 'reset' };

const flyInContextReducer: Reducer<StateContextType, DispatchAction> = (state, action) => {
    switch (action.type) {
        case 'setTriedToClose':
            return {
                ...state,
                triedToClose: action.payload,
            };
        case 'setTriedToBack':
            return {
                ...state,
                triedToBack: action.payload,
            };
        case 'setTriedToOutsideClick':
            return {
                ...state,
                triedToOutsideClick: action.payload,
            };
        case 'setBlockingContext':
            return {
                ...state,
                block: action.payload,
            };
        case 'unblockAll':
            return {
                ...state,
                block: {
                    outsideClickBlocked: false,
                    backBlocked: false,
                    closeBlocked: false,
                },
            };
        case 'blockAll':
            return {
                ...state,
                block: {
                    outsideClickBlocked: true,
                    backBlocked: true,
                    closeBlocked: true,
                },
            };
        case 'reset':
            return {
                ...state,
                triedToClose: false,
                triedToBack: false,
                triedToOutsideClick: false,
            };
        default:
            return state;
    }
};

export const FlyInContextProvider: FC<React.PropsWithChildren<unknown>> = (props) => {
    const [state, dispatch] = useReducer<Reducer<StateContextType, DispatchAction>>(
        flyInContextReducer,
        {
            block: {
                outsideClickBlocked: false,
                backBlocked: false,
                closeBlocked: false,
            },
            triedToBack: false,
            triedToClose: false,
            triedToOutsideClick: false,
        },
    );

    return (
        <DispatchContext.Provider value={dispatch}>
            <StateContext.Provider value={state}>{props.children}</StateContext.Provider>
        </DispatchContext.Provider>
    );
};

export const useFlyInStateContext = () => {
    const state = useContext(StateContext);

    return state;
};

export const useFlyInDispatchContext = () => {
    const dispatch = useContext(DispatchContext);

    const dispatchOrError = useCallback(
        (action: DispatchAction) => {
            if (dispatch) {
                dispatch(action);
            } else {
                logMissingContext('FlyIn');
            }
        },
        [dispatch],
    );

    const unblockAll = useCallback(() => {
        dispatchOrError({
            type: 'unblockAll',
        });
    }, [dispatchOrError]);

    const blockAll = useCallback(() => {
        dispatchOrError({
            type: 'blockAll',
        });
    }, [dispatchOrError]);

    const setTriedToOutsideClick = useCallback(() => {
        dispatchOrError({
            type: 'setTriedToOutsideClick',
            payload: true,
        });
    }, [dispatchOrError]);

    const setTriedToClose = useCallback(() => {
        dispatchOrError({
            type: 'setTriedToClose',
            payload: true,
        });
    }, [dispatchOrError]);

    const setTriedToBack = useCallback(() => {
        dispatchOrError({
            type: 'setTriedToBack',
            payload: true,
        });
    }, [dispatchOrError]);

    const unsetTriedToClose = useCallback(() => {
        dispatchOrError({
            type: 'setTriedToClose',
            payload: false,
        });
    }, [dispatchOrError]);

    const unsetTriedToBack = useCallback(() => {
        dispatchOrError({
            type: 'setTriedToBack',
            payload: false,
        });
    }, [dispatchOrError]);

    const unsetTriedToOutsideClick = useCallback(() => {
        dispatchOrError({
            type: 'setTriedToOutsideClick',
            payload: false,
        });
    }, [dispatchOrError]);

    const reset = useCallback(() => {
        dispatchOrError({
            type: 'reset',
        });
    }, [dispatchOrError]);

    return {
        reset,
        setTriedToBack,
        setTriedToClose,
        unsetTriedToBack,
        unsetTriedToClose,
        setTriedToOutsideClick,
        unsetTriedToOutsideClick,
        blockAll,
        unblockAll,
    };
};
