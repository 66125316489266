/* eslint-disable unused-imports/no-unused-vars */
import { Accordion, Icon } from '@coop/components';
import {
    Bag1Icon,
    CalendarIcon,
    CardIcon,
    OrdersIcon,
    OverviewIcon,
    PaperIcon,
    SettingsIcon,
    SubscriptionIcon,
} from '@coop/icons';
import classnames from 'classnames';
import { type FC, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { isAriaCurrentPage } from '../../../common/utils/accessibilityUtils';
import { urlFriendlySlug } from '../../../common/utils/sanitizeUtils';
import type { MyCoopProfileMenuItem } from '../../../episerver/api/localContentApi';
import styles from './ProfileMenuList.module.less';
import { getTopActivePage } from './ProfileMenuList.utils';

interface ProfileMenuListProps {
    pages: MyCoopProfileMenuItem[];
    activePageId?: number;
    onItemClick?: (
        e: React.MouseEvent<HTMLAnchorElement>,
        item: MyCoopProfileMenuItem,
        parentItem?: MyCoopProfileMenuItem,
    ) => void;
    onItemOpen?: (item: MyCoopProfileMenuItem) => void;
    onItemClose?: (item: MyCoopProfileMenuItem) => void;
    isSideMenu?: boolean;
}

const getMenuPageById = (pages: MyCoopProfileMenuItem[], id: string) => {
    return pages.find((page) => page.id === Number(id));
};

const ProfileMenuList: FC<React.PropsWithChildren<ProfileMenuListProps>> = (props) => {
    const { pathname } = useLocation();
    const previouslyExpandedRef = useRef<string>('');
    const [expandedId, setExpandedId] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (props.activePageId) {
            const expandedPageId = props.pages
                .find((page) => !!getTopActivePage(page, props.activePageId!))
                ?.id.toString();

            setExpandedId(expandedPageId || ''); // Accordion uses empty string to indicate collapsed item
        }
    }, [props.activePageId, props.pages]);

    return (
        <nav aria-label="Profilmenyn" className={styles.List}>
            <Accordion.Root
                collapsible
                type="single"
                className={styles.ProfileMenuAccordion}
                asChild
                value={expandedId}
                onValueChange={(_expandedId) => {
                    if (_expandedId) {
                        const expandedItem = getMenuPageById(props.pages, _expandedId);
                        if (expandedItem) {
                            props.onItemOpen?.(expandedItem);
                        }
                    } else {
                        const expandedItem = getMenuPageById(
                            props.pages,
                            previouslyExpandedRef.current,
                        );
                        if (expandedItem) {
                            props.onItemClose?.(expandedItem);
                        }
                    }

                    setExpandedId(_expandedId);
                    previouslyExpandedRef.current = _expandedId;
                }}
            >
                <ul>
                    {props.pages.map((myCoopProfilePage: MyCoopProfileMenuItem) => (
                        <MenuItemAccordion
                            currentPath={pathname}
                            key={myCoopProfilePage.id}
                            item={myCoopProfilePage}
                            level={1}
                            activePageId={props.activePageId}
                            onItemClick={props.onItemClick}
                            onItemOpen={props.onItemOpen}
                            onItemClose={props.onItemClose}
                            isSideMenu={props.isSideMenu}
                        />
                    ))}
                </ul>
            </Accordion.Root>
        </nav>
    );
};

// Its Epi who says what icon belong to what page, we need to map it to extract icon component from it
const mappedBackendIconsToComponents = (iconName: string) => {
    switch (iconName) {
        case 'paper':
            return PaperIcon;
        case 'calendar':
            return CalendarIcon;
        case 'overview':
            return OverviewIcon;
        case 'orders':
            return OrdersIcon;
        case 'bag1':
            return Bag1Icon;
        case 'subscription':
            return SubscriptionIcon;
        case 'card':
            return CardIcon;
        case 'settings':
            return SettingsIcon;
        default:
            return null;
    }
};

const ProfileMenuListItemIcon: FC<React.PropsWithChildren<{ item: MyCoopProfileMenuItem }>> = ({
    item,
}) => {
    const iconComponent = mappedBackendIconsToComponents(item.icon);

    if (iconComponent) {
        return <Icon icon={iconComponent} width={24} height={24} aria-hidden />;
    }

    return null;
};

// Wrapper component to remove some of accordion controls when Accordion item is a link
const Link = React.forwardRef<HTMLAnchorElement, React.ComponentPropsWithoutRef<'a'>>(
    (props, ref) => {
        const {
            'aria-expanded': ariaExpanded,
            'aria-controls': ariaControls,
            type,
            ...rest
        } = props;

        return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <a {...rest} ref={ref}>
                {props.children}
            </a>
        );
    },
);

const MenuItemAccordion: FC<
    React.PropsWithChildren<{
        currentPath: string;
        item: MyCoopProfileMenuItem;
        level: number;
        activePageId?: number;
        onItemClick?: (
            e: React.MouseEvent<HTMLAnchorElement>,
            item: MyCoopProfileMenuItem,
            parentItem?: MyCoopProfileMenuItem,
        ) => void;
        onItemOpen?: (item: MyCoopProfileMenuItem) => void;
        onItemClose?: (item: MyCoopProfileMenuItem) => void;
        isSideMenu?: boolean;
        showIcon?: boolean;
    }>
> = ({
    currentPath,
    item: menuPageItem,
    level,
    activePageId,
    onItemClick,
    onItemOpen,
    onItemClose,
    isSideMenu,
    showIcon = true,
}) => {
    // business requirement to show expandable children only on level 1
    const hasChildren = menuPageItem.menuItemChildren.length > 0 && level < 2;

    const active = !!activePageId && !!getTopActivePage(menuPageItem, activePageId);
    const ariaCurrentPage = active && isAriaCurrentPage(menuPageItem.url || '', currentPath);

    return (
        <Accordion.Item
            className={styles.ProfileMenuAccordionItem}
            value={menuPageItem.id.toString()}
            asChild
            key={menuPageItem.id}
        >
            <li>
                <Accordion.Header asChild className={styles.AccordionHeader}>
                    {hasChildren ? (
                        <Accordion.Trigger className={styles.Trigger}>
                            <div
                                className={styles.AccordionItem}
                                data-test={`profileMenuItem-${urlFriendlySlug(menuPageItem.name)}`}
                            >
                                <div className={styles.SvgWrapper}>
                                    <ProfileMenuListItemIcon item={menuPageItem} />
                                </div>
                                <div
                                    className={classnames(
                                        styles.AccordionItemName,
                                        isSideMenu ? styles.AccordionItemNameSideMenu : '',
                                    )}
                                >
                                    {menuPageItem.name}
                                </div>
                            </div>
                        </Accordion.Trigger>
                    ) : (
                        <Accordion.Trigger
                            className={classnames(
                                styles.Trigger,
                                level > 1 && styles.SubLevel,
                                active && styles.isActive,
                                styles.Item,
                            )}
                            asChild
                            withChevron={false}
                            aria-current={ariaCurrentPage}
                        >
                            <Link
                                href={menuPageItem.url}
                                key={`${menuPageItem.id}`}
                                onClick={(e) => onItemClick?.(e, menuPageItem)}
                            >
                                <div
                                    className={styles.AccordionItem}
                                    data-test={`profileMenuItem-${urlFriendlySlug(
                                        menuPageItem.name,
                                    )}`}
                                >
                                    {showIcon && (
                                        <div className={styles.SvgWrapper}>
                                            <ProfileMenuListItemIcon item={menuPageItem} />
                                        </div>
                                    )}
                                    <div className={styles.Link}>{menuPageItem.name}</div>
                                </div>
                            </Link>
                        </Accordion.Trigger>
                    )}
                </Accordion.Header>
                {hasChildren && (
                    <Accordion.Content>
                        <Accordion.Root
                            collapsible
                            type="single"
                            className={classnames(
                                styles.ProfileMenuAccordion,
                                styles.AccordionContent,
                            )}
                            asChild
                            value={activePageId?.toString()}
                        >
                            <ul>
                                {menuPageItem.menuItemChildren.map((myCoopProfileChildPage) => (
                                    <MenuItemAccordion
                                        currentPath={currentPath}
                                        key={myCoopProfileChildPage.id}
                                        item={myCoopProfileChildPage}
                                        level={level + 1}
                                        activePageId={activePageId}
                                        onItemClick={(e) =>
                                            onItemClick?.(e, myCoopProfileChildPage, menuPageItem)
                                        }
                                        onItemOpen={onItemOpen}
                                        onItemClose={onItemClose}
                                        isSideMenu={isSideMenu}
                                        showIcon={false}
                                    />
                                ))}
                            </ul>
                        </Accordion.Root>
                    </Accordion.Content>
                )}
            </li>
        </Accordion.Item>
    );
};

export default ProfileMenuList;
