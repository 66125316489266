import { ConfirmInfo } from '@coop/components';
import classNames from 'classnames';
import type { FC } from 'react';
import type React from 'react';
import { useState } from 'react';

import { Helpers } from '../../../../utility/helpers';
import { useMyCoopSettingsPageQuery } from '../../hooks/useMyCoopSettingsPageQuery';
import styles from './BonusInfoConfirm.module.scss';

export interface BonusInfoConfirmProps {
    monetaryWorth: number;
    cssClasses: string;
    onClickOpen?: () => void;
}

const BonusInfoConfirm: FC<React.PropsWithChildren<BonusInfoConfirmProps>> = (props) => {
    const { data: myCoopSettings } = useMyCoopSettingsPageQuery();

    const [show, setShow] = useState(false);
    const handleOpenClick = () => {
        setShow(true);
    };

    return (
        <>
            <button
                className={classNames(props.cssClasses, styles.Button)}
                type="button"
                onClick={() => {
                    handleOpenClick();
                    props.onClickOpen?.();
                }}
            >
                Motsvarar{' '}
                <span className="u-textWeightBold ">
                    {Helpers.formatMemberPointsAndAmount(props.monetaryWorth)}
                </span>{' '}
                kr hos Coop
            </button>
            {myCoopSettings?.bonusInfoNotificationHeadline &&
                myCoopSettings?.bonusInfoNotificationText && (
                    <ConfirmInfo
                        show={show}
                        heading={myCoopSettings.bonusInfoNotificationHeadline}
                        message={myCoopSettings.bonusInfoNotificationText}
                        close={() => setShow(false)}
                    />
                )}
        </>
    );
};

export default BonusInfoConfirm;
