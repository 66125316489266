import { Button, Icon, Loader, ModalHeader } from '@coop/components';
import { CheckmarkIcon, CloseIcon, DottedCircleIcon, Warning1Icon } from '@coop/icons';
import { useQuery } from '@tanstack/react-query';
import classnames from 'classnames';
import type { FC } from 'react';

import { appConfig } from '../../../../../appConfig';
import { Helpers } from '../../../../../utility/helpers';
import loyaltyFlow from '../../../../common/flow/loyaltyFlow';
import {
    useHasUserAKimCustomerId,
    useIsUserAMember,
    useIsUserCompany,
} from '../../../../common/hooks/useUserProfile';
import { ga4BonusEvents } from '../../../../common/tracking/ga4/bonus';
import { useHouseholdPointBalance } from '../../../../member/pointshop/hooks/useHouseholdPointBalance';
import BonusInfoConfirm from '../../../../myCoop/components/BonusInfoConfirm';
import { useMyCoopSettingsPageQuery } from '../../../../myCoop/hooks/useMyCoopSettingsPageQuery';
import { associationNamesQueryKey } from '../../../../myCoop/myCoopQueries';
import styles from './MenuHeaderBonusSystem.module.less';

interface MenuChildrenProps {
    isEligibleForBonus: boolean | undefined;
    myBonusOverviewPageUrl: string;
    handleLinkClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

const MenuHeaderBonusSystem: FC<MenuChildrenProps> = ({
    isEligibleForBonus,
    myBonusOverviewPageUrl,
    handleLinkClick,
}) => {
    const isMember = useIsUserAMember();
    const isCompany = useIsUserCompany();

    const hasKimCustomerId = useHasUserAKimCustomerId();
    const { isShoppingForAnother } = appConfig.coopUserSettings;

    const {
        isSuccess: pointsIsSuccess,
        data: result,
        isLoading: pointsIsLoading,
    } = useHouseholdPointBalance(!!isMember);

    const { data: associationNames, isLoading: associationIsLoading } = useQuery({
        queryKey: associationNamesQueryKey,
        queryFn: loyaltyFlow.getMembershipNames,
        enabled: !!isMember,
    });

    // A member needs to have at least one association

    const memberMissingAssociations =
        isMember &&
        ((!associationIsLoading && !associationNames) ||
            (associationNames && associationNames.length < 1));

    const { data: settingsTexts } = useMyCoopSettingsPageQuery([
        'brokenMemberHeadline',
        'brokenMemberText',
        'brokenMemberButtonContentReference',
        'brokenMemberButtonText',
        'notMemberHeadline',
        'notMemberPoint1',
        'notMemberPoint2',
        'notMemberPoint3',
        'notMemberPoint1Checked',
        'notMemberPoint2Checked',
        'notMemberPoint3Checked',
        'notMemberPoint1CheckedText',
        'notMemberPoint1UnCheckedText',
        'notMemberPoint2CheckedText',
        'notMemberPoint2UnCheckedText',
        'notMemberPoint3CheckedText',
        'notMemberPoint3UnCheckedText',
        'notMemberButtonText',
        'notMemberButtonContentReference',
        'notConnectedButtonText',
        'notConnectedButtonContentReference',
    ]);
    if (!settingsTexts) return null;

    if (memberMissingAssociations) {
        return (
            <div className={styles.HeaderBroken}>
                <div className={styles.Circle}>
                    <Icon
                        icon={Warning1Icon}
                        className="u-colorRed"
                        width={32}
                        height={32}
                        title="Varning"
                    />
                </div>
                <ModalHeader className={styles.Headline}>
                    {settingsTexts.brokenMemberHeadline
                        ? settingsTexts.brokenMemberHeadline
                        : 'Något gick fel. Poäng kunde inte hämtas.'}
                </ModalHeader>
                {settingsTexts.brokenMemberText && <p>{settingsTexts.brokenMemberText}</p>}
                {settingsTexts.brokenMemberButtonContentReference?.url &&
                    settingsTexts.brokenMemberButtonText && (
                        <Button asChild theme="primary" className="u-sizeFull">
                            <a
                                href={settingsTexts.brokenMemberButtonContentReference?.url}
                                className={styles.NotAMemberButton}
                            >
                                {settingsTexts.brokenMemberButtonText}
                            </a>
                        </Button>
                    )}
            </div>
        );
    }

    return (
        <div
            className={classnames(
                styles.Header,
                isCompany || isShoppingForAnother ? styles.HeaderCompany : '',
            )}
        >
            {!isMember && (
                <div className={styles.NotMember}>
                    {isCompany || isShoppingForAnother ? (
                        <ModalHeader className={styles.HeadlineCompany}>
                            {isCompany ? 'Företagskonto' : ''}
                        </ModalHeader>
                    ) : (
                        <>
                            <ModalHeader className={styles.Headline}>
                                {settingsTexts.notMemberHeadline
                                    ? settingsTexts.notMemberHeadline
                                    : 'Inloggad (ej medlem)'}
                            </ModalHeader>
                            {(settingsTexts.notMemberPoint1 ||
                                settingsTexts.notMemberPoint2 ||
                                settingsTexts.notMemberPoint3) && (
                                <ul>
                                    {settingsTexts.notMemberPoint1 && (
                                        <li>
                                            <ListIcon
                                                isChecked={!!settingsTexts.notMemberPoint1Checked}
                                                aria-hidden
                                            />
                                            <div aria-hidden>{settingsTexts.notMemberPoint1}</div>
                                            <span className="u-hiddenVisually">
                                                {settingsTexts.notMemberPoint1Checked
                                                    ? settingsTexts.notMemberPoint1CheckedText
                                                    : settingsTexts.notMemberPoint1UnCheckedText}
                                            </span>
                                        </li>
                                    )}

                                    {settingsTexts.notMemberPoint2 && (
                                        <li>
                                            <ListIcon
                                                isChecked={!!settingsTexts.notMemberPoint2Checked}
                                                aria-hidden
                                            />
                                            <div aria-hidden>{settingsTexts.notMemberPoint2}</div>
                                            <span className="u-hiddenVisually">
                                                {settingsTexts.notMemberPoint2Checked
                                                    ? settingsTexts.notMemberPoint2CheckedText
                                                    : settingsTexts.notMemberPoint2UnCheckedText}
                                            </span>
                                        </li>
                                    )}

                                    {settingsTexts.notMemberPoint3 && (
                                        <li>
                                            <ListIcon
                                                isChecked={!!settingsTexts.notMemberPoint3Checked}
                                                aria-hidden
                                            />
                                            <div aria-hidden>{settingsTexts.notMemberPoint3}</div>
                                            <span className="u-hiddenVisually">
                                                {settingsTexts.notMemberPoint3Checked
                                                    ? settingsTexts.notMemberPoint3CheckedText
                                                    : settingsTexts.notMemberPoint3UnCheckedText}
                                            </span>
                                        </li>
                                    )}
                                </ul>
                            )}

                            {settingsTexts.notMemberButtonText &&
                                settingsTexts.notMemberButtonContentReference?.url && (
                                    <Button asChild theme="primary" fullWidth>
                                        <a
                                            href={settingsTexts.notMemberButtonContentReference.url}
                                            onClick={handleLinkClick}
                                            className={styles.NotAMemberButton}
                                        >
                                            {settingsTexts.notMemberButtonText}
                                        </a>
                                    </Button>
                                )}
                            {!hasKimCustomerId &&
                                settingsTexts.notConnectedButtonText &&
                                settingsTexts.notConnectedButtonContentReference?.url && (
                                    <div className={styles.NotConnectedTextLink}>
                                        Redan medlem?{' '}
                                        <a
                                            href={
                                                settingsTexts.notConnectedButtonContentReference.url
                                            }
                                            onClick={handleLinkClick}
                                        >
                                            {settingsTexts.notConnectedButtonText}
                                        </a>
                                    </div>
                                )}
                        </>
                    )}
                </div>
            )}
            {isMember && (
                <div className={styles.Header}>
                    <ModalHeader className={styles.PrePoints}>Bonus att använda</ModalHeader>

                    {pointsIsSuccess && (
                        <>
                            <div className={classnames(styles['Points-value'], 'u-textCenter')}>
                                {isEligibleForBonus && result ? (
                                    <>
                                        <span>
                                            {Helpers.formatMemberPointsAndAmount(result.points)}
                                        </span>
                                        <span aria-hidden> p</span>
                                        <span className="u-hiddenVisually">poäng</span>
                                    </>
                                ) : (
                                    '-'
                                )}
                            </div>

                            {isEligibleForBonus && result && (
                                <div className={styles.PostPoints}>
                                    <BonusInfoConfirm
                                        monetaryWorth={result.monetaryWorth}
                                        cssClasses={styles.InformationButton}
                                        onClickOpen={() => {
                                            ga4BonusEvents.navigation.infoSymbolInteraction(
                                                'profile menu',
                                            );
                                        }}
                                    />
                                </div>
                            )}
                        </>
                    )}
                    {pointsIsLoading && (
                        <Loader theme="green" additionalClassNames={styles.PointLoader} />
                    )}

                    {myBonusOverviewPageUrl && (
                        <Button asChild theme="primary" fullWidth>
                            <a href={myBonusOverviewPageUrl} onClick={handleLinkClick}>
                                Till översikten
                            </a>
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
};

const ListIcon: FC<{ isChecked: boolean }> = ({ isChecked }) => {
    if (isChecked) {
        return (
            <Icon
                icon={CheckmarkIcon}
                className={styles.IconChecked}
                width={32}
                height={32}
                aria-hidden
            />
        );
    }

    return (
        <div className={styles.IconCircleX}>
            <Icon icon={DottedCircleIcon} className={styles.IconNotChecked} aria-hidden />
            <Icon icon={CloseIcon} width={16} height={16} className={styles.IconX} aria-hidden />
        </div>
    );
};

export default MenuHeaderBonusSystem;
