import { appConfig } from '../../../appConfig';
import { safeSplit } from './stringUtils';

export const isAriaCurrentPage = (pathToMatch: string, currentPagePath: string) => {
    const b2bStartPageSegment = appConfig.coopSettings.b2b.startPage?.segmentUrl;

    const currentSegments = safeSplit(currentPagePath, '/', { removeEmptyEntries: true });
    const segmentsToMatch = safeSplit(pathToMatch, '/', { removeEmptyEntries: true });

    // means we check against b2c startpage itself, need to check if we are on b2b startpage
    if (
        segmentsToMatch.length === 0 &&
        b2bStartPageSegment &&
        currentSegments.includes(b2bStartPageSegment)
    ) {
        return false;
    }

    for (let i = 0; i < currentSegments.length; i += 1) {
        if (currentSegments[i] === segmentsToMatch[i]) {
            currentSegments.splice(i, 1);
            segmentsToMatch.splice(i, 1);
            i -= 1; // we need to check the same index again, as we removed the element
        }
    }

    if (currentSegments.length === 0 && segmentsToMatch.length === 0) {
        return 'page';
    }

    // we exhausted all segments to match, but there are still some on current path. This is partial match.
    if (currentSegments.length > 0 && segmentsToMatch.length === 0) {
        return true;
    }

    return false;
};
