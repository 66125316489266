import type { FC } from 'react';
import { createContext, useContext, useMemo } from 'react';

export const enum MicroAppContextType {
    Checkout,
    Start,
    Ecommerce,
    Stores,
    CnC,
    Recipes,
    MyCoop,
}

interface IMicroAppContext {
    microAppContextType?: MicroAppContextType;
}

const MicroAppContext = createContext<IMicroAppContext>({ microAppContextType: undefined });

export const useMicroAppContext = () => {
    const featureContext = useContext(MicroAppContext);
    return featureContext;
};

export const useIsInCheckoutMicroApp = () => {
    const context = useContext(MicroAppContext);

    return context?.microAppContextType === MicroAppContextType.Checkout;
};

export const useIsInEcommerceMicroApp = () => {
    const context = useContext(MicroAppContext);

    return context?.microAppContextType === MicroAppContextType.Ecommerce;
};

export const useIsInCncMicroApp = () => {
    const context = useContext(MicroAppContext);

    return context?.microAppContextType === MicroAppContextType.CnC;
};

export const MicroAppContextProvider: FC<
    React.PropsWithChildren<{ microAppType: MicroAppContextType }>
> = (props) => {
    const value = useMemo<IMicroAppContext>(
        () => ({ microAppContextType: props.microAppType }),
        [props.microAppType],
    );

    return <MicroAppContext.Provider value={value}>{props.children}</MicroAppContext.Provider>;
};
