import classNames from 'classnames';
import * as React from 'react';

import styles from './Search.module.scss';

const SearchContent = React.forwardRef<HTMLDivElement, React.PropsWithChildren>((props, ref) => {
    return (
        <div ref={ref} className={classNames(styles['Search-content'], 'u-noPrint')}>
            {props.children}
        </div>
    );
});

export default SearchContent;
