import type { TransactionHistory, TransactionHistoryApi } from '../../myCoop/myPoints/models';
import { getAuthToken } from '../api/auth/getAuthToken';
import { externalLoyaltyApi } from '../api/loyalty/externalLoyaltyApi';

const getExpiringPoints = async (months: number) => {
    const identity = await getAuthToken();
    return externalLoyaltyApi.getExpiringPoints(months, identity.token);
};

const getEligibleForBonus = async () => {
    const identity = await getAuthToken();
    const result = await externalLoyaltyApi.getEligibleForBonus(identity.token);
    return result;
};

const getHouseholdMembers = async () => {
    const identity = await getAuthToken();
    const details = await externalLoyaltyApi.getHouseholdMembers(identity.token);
    return details;
};

const getPointBalance = async () => {
    const identity = await getAuthToken();
    const pointBalance = await externalLoyaltyApi.getPointBalance(identity.token);
    return pointBalance;
};

const getPreviousMonthBonus = async () => {
    const identity = await getAuthToken();
    const previousMonthBonus = await externalLoyaltyApi.getPreviousMonthBonus(identity.token);
    return previousMonthBonus;
};

const getPointsMatrixStructureLevel = async () => {
    const identity = await getAuthToken();
    const pointsMatrixStructureLevel = await externalLoyaltyApi.getPointsMatrixStructureLevel(
        identity.token,
    );
    return pointsMatrixStructureLevel;
};

const getPointsSummary = async () => {
    const identity = await getAuthToken();
    const pointsSummary = await externalLoyaltyApi.getPointsSummary(identity.token);
    return pointsSummary;
};

const getPointTypeTranslated = (pointType: number) => {
    if (pointType === 1) {
        return 'CoopStorePointsEarned';
    }
    if (pointType === 2) {
        return 'PaymentPointsInternal';
    }
    if (pointType === 3) {
        return 'CampaignPoints';
    }
    if (pointType === 4) {
        return 'GamificationPoints';
    }
    if (pointType === 5) {
        return 'PartnerPoints';
    }
    if (pointType === 6) {
        return 'ManualPoints';
    }
    if (pointType === 7) {
        return 'HouseholdChangeMerge';
    }
    if (pointType === 8) {
        return 'CoopStorePointsSpent';
    }
    if (pointType === 9) {
        return 'PartnerPointsSpent';
    }
    if (pointType === 10) {
        return 'ExpirePoints';
    }
    if (pointType === 11) {
        return 'HouseholdChangeSplit';
    }
    if (pointType === 12) {
        return 'PaymentPointsExternal';
    }
    if (pointType === 13) {
        return 'ExchangePoints';
    }
    if (pointType === 14) {
        return 'CoopXtraPoints';
    }

    return 'NotSet';
};

// Poänguttag
const getPointWithdrawalTransactionsHistory = async (
    fromDate: Date,
    toDate?: Date,
    page?: number,
    size?: number,
) => {
    const identity = await getAuthToken();

    if (toDate) {
        const request: DateTransactionsRequest = {
            from: fromDate,
            to: toDate,
            page: page ?? 0,
            size: size ?? 5,
            filter: 'POINT_USAGES',
        };

        const history: TransactionHistory[] = (
            await externalLoyaltyApi.getTransactionsHistoryByDates(identity.token, request)
        ).map((trans: TransactionHistoryApi) => {
            return {
                ...trans,
                pointTypeTranslated: getPointTypeTranslated(trans.pointType),
            };
        });

        return history;
    }

    const request: TransactionsRequest = {
        page: page ?? 0,
        size: size ?? 5,
        filter: 'POINT_USAGES',
    };
    const monthTrans: TransactionHistory[] = (
        await externalLoyaltyApi.getMonthTransactionsHistory(identity.token, request, fromDate)
    ).map((trans: TransactionHistoryApi) => {
        return {
            ...trans,
            pointTypeTranslated: getPointTypeTranslated(trans.pointType),
        };
    });

    return monthTrans;
};

const getPointTransactionsHistory = async (
    fromDate: Date,
    toDate?: Date,
    page?: number,
    size?: number,
) => {
    const identity = await getAuthToken();
    if (toDate) {
        const request: DateTransactionsRequest = {
            from: fromDate,
            to: toDate,
            page: page ?? 0,
            size: size ?? 5,
            filter: 'POINT_PURCHASE_HISTORY',
        };
        const history: TransactionHistory[] = (
            await externalLoyaltyApi.getTransactionsHistoryByDates(identity.token, request)
        ).map((trans: TransactionHistoryApi) => {
            return {
                ...trans,
                pointTypeTranslated: getPointTypeTranslated(trans.pointType),
            };
        });

        return history;
    }

    // If no toDate, then this current month
    const request: TransactionsRequest = {
        page: page ?? 0,
        size: size ?? 5,
        filter: 'POINT_PURCHASE_HISTORY',
    };
    const history: TransactionHistory[] = (
        await externalLoyaltyApi.getMonthTransactionsHistory(identity.token, request, fromDate)
    ).map((trans: TransactionHistoryApi) => {
        return {
            ...trans,
            pointTypeTranslated: getPointTypeTranslated(trans.pointType),
        };
    });

    return history;
};

// Köphistorik
const getMonetaryPurchaseTransactionsHistory = async (
    fromDate: Date,
    page?: number,
    size?: number,
) => {
    const identity = await getAuthToken();
    return externalLoyaltyApi.getMonthMonetaryTransactionsHistory(
        identity.token,
        fromDate,
        page,
        size,
    );
};

const getPointsSummaryYearly = async () => {
    const identity = await getAuthToken();
    const pointsSummaryYearly = await externalLoyaltyApi.getPointsSummaryYearly(identity.token);
    return pointsSummaryYearly;
};

const cachedVouchers: { [key: string]: VoucherDetailsResponse } = {};

const getVoucherDetails = async (id: string) => {
    if (cachedVouchers[id]) {
        return cachedVouchers[id];
    }

    const identity = await getAuthToken();
    const voucher = await externalLoyaltyApi.getVoucherDetails(id, identity.token);
    cachedVouchers[id] = voucher;
    return voucher;
};

const purchaseOffer = async (offerId: number, specialCredentials: PurchaseOfferRequest) => {
    const identity = await getAuthToken();
    const offer = await externalLoyaltyApi.purchaseOffer(
        offerId,
        specialCredentials,
        identity.token,
    );
    return offer;
};

const autoVoucherStatus = async () => {
    const identity = await getAuthToken();
    // If voucher has mmid, then it is on
    const voucherResult = await externalLoyaltyApi.getAutoVoucherStatus(identity.token);
    return voucherResult?.mmid !== 0 ? 'On' : 'Off';
};

const activateAutoVoucher = async () => {
    const identity = await getAuthToken();
    await externalLoyaltyApi.activateAutoVoucher(identity.token);
    return true;
};

const deactivateAutoVoucher = async () => {
    const identity = await getAuthToken();
    await externalLoyaltyApi.deactivateAutoVoucher(identity.token);
    return true;
};

const getConnectedCards = async () => {
    const identity = await getAuthToken();
    const cards = await externalLoyaltyApi.getConnectedCards(identity.token);
    if (cards === null) {
        return [];
    }
    return cards;
};

const deleteConnectedCard = async (id: string) => {
    const identity = await getAuthToken();
    const success = await externalLoyaltyApi.deleteConnectedCard(id, identity.token);
    return success;
};

const addHouseholdMember = async (personalNumber: string) => {
    const identity = await getAuthToken();
    const success = await externalLoyaltyApi.addHouseholdMember(personalNumber, identity.token);
    return success;
};

const getMembershipNames = async () => {
    const identity = await getAuthToken();
    const membershipNames = await externalLoyaltyApi.getMembershipNames(identity.token);
    return membershipNames?.associations;
};

const pointshopFlow = {
    getExpiringPoints,
    getEligibleForBonus,
    getHouseholdMembers,
    getPointBalance,
    getPreviousMonthBonus,
    getPointsMatrixStructureLevel,
    getPointsSummary,
    getPointsSummaryYearly,
    getPointWithdrawalTransactionsHistory,
    getPointTransactionsHistory,
    getMonetaryPurchaseTransactionsHistory,
    getVoucherDetails,
    purchaseOffer,
    autoVoucherStatus,
    activateAutoVoucher,
    deactivateAutoVoucher,
    getConnectedCards,
    deleteConnectedCard,
    addHouseholdMember,
    getMembershipNames,
};

export default pointshopFlow;
