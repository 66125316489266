/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import * as React from 'react';

import styles from './Search.module.scss';

const SearchInputSlot = React.forwardRef<HTMLInputElement, React.ComponentProps<'input'>>(
    (props, ref) => {
        return (
            <input
                ref={ref}
                size={1} // Enables it to shrink below default 20 which is needed for small screens
                {...props}
                className={classNames(styles['Search-input'], props.className)}
            />
        );
    },
);

export default SearchInputSlot;
