/* eslint-disable react/jsx-props-no-spreading */

'use client';

import type * as React from 'react';
import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import type { CSSTransitionProps } from 'react-transition-group/CSSTransition';

import Badge from './Badge';
import styles from './Badge.module.scss';
import type { BadgeProps } from './Badge.types';

const AnimatedBadge = (
    props: React.PropsWithChildren<BadgeProps> & {
        animationProps: CSSTransitionProps;
    },
) => {
    const isUpdatingAndActive = [styles['is-updating'], styles['is-active']].join(' ');
    const isActive = styles['is-active'];

    const transitionRef = useRef(null);

    return (
        <CSSTransition
            nodeRef={transitionRef}
            {...props.animationProps}
            classNames={{
                appear: isUpdatingAndActive,
                appearActive: isUpdatingAndActive,
                appearDone: isActive,
                enter: isUpdatingAndActive,
                enterActive: isUpdatingAndActive,
                enterDone: isActive,
            }}
        >
            <Badge ref={transitionRef} {...props} className={styles['Badge--animated']} />
        </CSSTransition>
    );
};

export default AnimatedBadge;
