/* eslint-disable @typescript-eslint/no-throw-literal */
import type {
    MonetaryTransactionHistory,
    TransactionHistoryApi,
} from '../../../myCoop/myPoints/models';
import { createDefaultException } from '../../exceptions/exceptionUtils';
import type {
    ApiHouseholdMemberException,
    ApiLoyaltyAutoCheckException,
    ApiLoyaltyException,
    ApiLoyaltyMember20Exception,
} from '../clients/directLoyaltyAxiosClient';
import getDirectLoyaltyAxiosClient from '../clients/directLoyaltyAxiosClient';
import getLocalApiAxiosClient from '../clients/localApiAxiosClient';
import type { CustomErrorInterceptor } from '../clients/localApiAxiosErrorInterceptors';

export const voucherApiUrl = '/voucher/automatic';
export const eligibleApiUrl = 'points/eligible';
export const previousPointsApiUrl = 'points/summary/previous';

/**
 * Point that expires within the given number of months
 */
const getExpiringPoints = async (months: number, token: string) => {
    const result = await getDirectLoyaltyAxiosClient(token).get<ExpiringPointsResponse[]>(
        `points/expiring/${months}`,
        {
            params: { 'api-version': 'v1' },
        },
    );
    return result.data;
};

/**
 * Household members, including self
 */
const getHouseholdMembers = async (token: string): Promise<HouseholdMember[]> => {
    const result =
        await getDirectLoyaltyAxiosClient(token).get<HouseholdResponse>('membership/household');
    return result.data?.members;
};

/**
 * Associations
 */
const getMembershipNames = async (token: string): Promise<MembershipNames> => {
    const response = await getDirectLoyaltyAxiosClient(token).get(`membership/associations/`);
    return response.data;
};

/**
 * Eligible for bonus - if user is ok to get and use bonus points
 */
const getEligibleForBonus = async (token: string): Promise<boolean> => {
    const result = await getDirectLoyaltyAxiosClient(token).get(eligibleApiUrl);
    return result.data?.eligibleForPoints || false;
};

/**
 * Total bonus
 */
const getPointBalance = async (token: string): Promise<ApiBonus> => {
    const result = await getDirectLoyaltyAxiosClient(token).get('points/balance', {
        params: { 'api-version': 'v3' },
    });
    return result.data;
};

/**
 * Previouss Monthly Bonus
 */
const getPreviousMonthBonus = async (token: string): Promise<ApiBonusGroup> => {
    const result = await getDirectLoyaltyAxiosClient(token).get(previousPointsApiUrl, {
        params: { 'api-version': 'v3' },
    });
    return result.data;
};

/**
 * Point matrix
 */
const getPointsMatrixStructureLevel = async (token: string): Promise<ApiPointsMatrix> => {
    const result = await getDirectLoyaltyAxiosClient(token).get('points/matrix?levels=5', {
        params: { 'api-version': 'v3' },
    });
    return result.data;
};

/**
 * Point summary / Current Month
 */
const getPointsSummary = async (token: string): Promise<ApiPointsSummary> => {
    const result = await getDirectLoyaltyAxiosClient(token).get('points/summary/current', {
        params: { 'api-version': 'v3' },
    });
    return result.data;
};

/**
 * Get transaction history by dates
 */
const getTransactionsHistoryByDates = async (
    token: string,
    request: DateTransactionsRequest,
): Promise<TransactionHistoryApi[]> => {
    try {
        const result = await getDirectLoyaltyAxiosClient(token, [
            transactionResponseInterceptor,
        ]).post('points/transactions', request, {
            params: { 'api-version': 'v3' },
        });
        return result.data || [];
    } catch (error) {
        const err = error as { code?: string };
        if (err.code && err.code === '204') {
            // If there is no transactions found, return empty array
            return [];
        }

        throw error;
    }
};

/**
 * Get transaction history for entire month
 */
const getMonthTransactionsHistory = async (
    token: string,
    request: TransactionsRequest,
    fromDate: Date,
): Promise<TransactionHistoryApi[]> => {
    try {
        const fromYear = fromDate.getFullYear();
        const fromMonth = fromDate.getMonth() + 1;

        const result = await getDirectLoyaltyAxiosClient(token, [
            transactionResponseInterceptor,
        ]).post(`points/transactions/${fromYear}/${fromMonth}`, request, {
            params: { 'api-version': 'v3' },
        });

        return result.data || [];
    } catch (error) {
        const err = error as { code?: string };
        if (err.code && err.code === '204') {
            // If there is no transactions found, return empty array
            return [];
        }

        throw error;
    }
};

/**
 * Purchase history - Köphistorik
 */
const getMonthMonetaryTransactionsHistory = async (
    token: string,
    fromDate: Date,
    page?: number,
    size?: number,
): Promise<MonetaryTransactionHistory[]> => {
    try {
        const fromYear = fromDate.getFullYear();
        const fromMonth = fromDate.getMonth() + 1;

        const result = await getDirectLoyaltyAxiosClient(token, [
            transactionResponseInterceptor,
        ]).get(`points/purchasehistory/${fromYear}/${fromMonth}`, {
            params: { 'api-version': 'v3', size: size ?? 5, page: page ?? 0 },
        });

        return result.data;
    } catch (error) {
        const err = error as { code?: string };
        if (err.code && err.code === '404') {
            // If there is no transactions found, return empty array
            return [];
        }

        throw error;
    }
};

const getPointsSummaryYearly = async (token: string): Promise<ApiPointsSummary> => {
    const result = await getDirectLoyaltyAxiosClient(token).get('points/summary/yearly', {
        params: { 'api-version': 'v3' },
    });
    return result.data;
};

const getVoucherDetails = async (id: string, token: string): Promise<VoucherDetailsResponse> => {
    const result = await getDirectLoyaltyAxiosClient(token).get(
        `/voucher/details/${encodeURI(id)}`,
    );
    return result.data;
};

const purchaseOffer = async (
    offerId: number,
    specialCredentials: PurchaseOfferRequest,
    token: string,
): Promise<PurchaseOfferResponse> => {
    const result = await getDirectLoyaltyAxiosClient(token, [
        purchaseOfferResponseInterceptor,
    ]).post(`/voucher/purchase/${offerId}`, specialCredentials);

    try {
        await getLocalApiAxiosClient().delete('/myPoints/cache');
        // eslint-disable-next-line no-empty
    } catch {}
    return result.data;
};

const transactionResponseInterceptor: CustomErrorInterceptor<ApiLoyaltyMember20Exception> = (
    response,
) => {
    if (response.response.data?.status === 404) {
        return createDefaultException(
            response.response.data.title,
            response.response.data.status.toString(),
        );
    }

    return createDefaultException('Något gick fel när transaktionerna skulle hämtas', '500');
};

const addMemberResponseInterceptor: CustomErrorInterceptor<ApiHouseholdMemberException> = (
    response,
) => {
    let msg = null;

    if (response.response.data?.error) {
        switch (response.response.data.error) {
            case 'INVALID_INPUT_SSN':
                msg = 'Personnumret är felaktigt eller i fel format.';
                break;
            case 'INVALID_INPUT_AGE':
                msg = 'Personen är under 18 år.';
                break;
            case 'INVALID_INPUT_HOUSEHOLD':
                msg =
                    'Den här medlemmen existerar redan i ett hushåll. Vänligen kontakta kundtjänst.';
                break;
            case 'FORBIDDEN_NOT_OWNER':
                msg =
                    'Något blev fel. Hushållstrukturen är felaktig. Vänligen kontakta kundservice om felet kvarstår.';
                break;
            default:
                msg = 'Något blev fel.';
                break;
        }
    }

    if (msg) {
        return createDefaultException(msg, response.response.data?.error);
    }

    return createDefaultException('Något blev fel.');
};

const purchaseOfferResponseInterceptor: CustomErrorInterceptor<ApiLoyaltyException> = (
    response,
) => {
    if (response.response.data?.errorCode) {
        let msg = null;

        switch (response.response.data.errorCode) {
            case 10:
            case 30:
                msg =
                    'Något gick fel när kundbilden skulle hämtas, vänligen kontakta kundservice. Felkod LMS_1030';
                break;
            case 20:
                msg =
                    'Poängvaran kunde inte hittas i systemet. Vänligen kontakta kundservice. Felkod LMS_20';
                break;
            case 40:
                msg = 'Ej tillräckligt med poäng för att ta poängvaran.';
                break;
            case 50:
            case 60:
                msg =
                    'Koderna för den här poängvaran är tillfälligt slut, vänligen försök igen senare.';
                break;
            case 70:
                msg =
                    'Något gick fel med sas-växlilngen, vänligen kontakta kundservice. Felkod SAS_70';
                break;
            case 190:
                msg = 'Något gick fel med växlingen, vänligen försök igen senare. Felkod SAS_190';
                break;
            case 80:
                msg = 'Max antal köp av denna poängvara är redan uppnådd.';
                break;
            case 90:
                msg =
                    'Något gick fel med växlingen. Se över ditt Nordic Choice konto. Felkod NORDIC_90';
                break;
            case 130:
            case 200:
                msg =
                    'Något gick fel med växlingen, vänligen försök igen senare. Felkod NORDIC_130';
                break;
            case 100:
                msg = 'Kvitto på uttaget kunde inte skickas.';
                break;
            case 110:
                msg = 'Något gick fel, vänligen försök igen senare. Felkod ADOB_110';
                break;
            case 120:
            case 140:
            case 150:
            case 170:
            case 180:
            case 210:
            case 220:
            case 230:
            case 240:
                msg = 'Något gick fel, vänligen försök igen senare. Felkod LMS_120';
                break;
            default:
                break;
        }

        if (msg) {
            return createDefaultException(msg, response.response.data.errorCode.toString());
        }
    }

    if (response.response.data?.status) {
        let msg = null;

        switch (response.response.status) {
            case 400:
                msg = 'Felaktiga uppgifter.';
                break;
            case 404:
                msg = 'Produkten finns för tillfället inte på lager.';
                break;
            case 504:
                msg = 'Tjänsten kunde inte nås för tillfället. Försök igen senare.';
                break;
            default:
                msg = 'Något gick fel, vänligen försök igen senare.';
                break;
        }

        if (msg) {
            return createDefaultException(msg, response.response.data.errorCode.toString());
        }
    }

    return null;
};

const getAutoVoucherStatus = async (token: string): Promise<AutoVoucherStatusResponse> => {
    const result = await getDirectLoyaltyAxiosClient(token, [autoStatusResponseInterceptor]).get(
        voucherApiUrl,
    );

    return result.data;
};

const autoStatusResponseInterceptor: CustomErrorInterceptor<ApiLoyaltyAutoCheckException> = (
    response,
) => {
    if (response.response.data?.status) {
        let msg = null;

        switch (response.response.data.status) {
            case 400:
                msg = 'Felaktiga uppgifter';
                break;
            case 401:
                msg = 'Accessproblem.';
                break;
            case 404:
                msg = 'Autocheck är avstängd'; // THIS IS OK, NOT AN ERROR
                break;
            case 504:
                msg = 'Tjänsten kunde inte nås för tillfället.';
                break;
            default:
                break;
        }

        if (msg) {
            return createDefaultException(msg, response.response.data.status.toString());
        }
    }

    return null;
};

const activateAutoVoucher = async (token: string) => {
    const result = await getDirectLoyaltyAxiosClient(token, [toggleCheckResponseInterceptor]).post(
        voucherApiUrl,
    );
    return result.data;
};

const deactivateAutoVoucher = async (token: string) => {
    const result = await getDirectLoyaltyAxiosClient(token, [
        toggleCheckResponseInterceptor,
    ]).delete(voucherApiUrl);
    return result.data;
};

const toggleCheckResponseInterceptor: CustomErrorInterceptor<ApiLoyaltyAutoCheckException> = (
    response,
) => {
    if (response.response.data?.status) {
        let msg = null;

        switch (response.response.data.status) {
            case 400:
                msg = 'Felaktiga uppgifter';
                break;
            case 401:
                msg = 'Accessproblem.';
                break;
            case 403:
                msg = 'Accessproblem (bankid).';
                break;
            case 404:
            case 504:
                msg = 'Tjänsten kunde inte nås för tillfället.';
                break;
            default:
                break;
        }

        if (msg) {
            return createDefaultException(msg, response.response.data.status.toString());
        }
    }

    return null;
};

const getConnectedCards = async (token: string) => {
    const result = await getDirectLoyaltyAxiosClient(token).get<CardResponse[]>(
        'membership/connected-cards',
    );
    return result.data;
};

const deleteConnectedCard = async (id: string, token: string) => {
    await getDirectLoyaltyAxiosClient(token).delete(`membership/connected-cards/${id}`);
    return true;
};

/**
 * Add household member
 * (Order extracard)
 */
const addHouseholdMember = async (personalNumber: string, token: string) => {
    const request: AddHouseholdMemberRequest = { socialSecurityNumber: personalNumber };
    await getDirectLoyaltyAxiosClient(token, [addMemberResponseInterceptor]).post(
        'membership/household',
        request,
        {
            params: { 'api-version': 'v1' },
        },
    );

    return true;
};

export interface AutoVoucherStatusResponse {
    // householdmmid: number;
    mmid: number;
    // lastPurchasedVoucherValue: number;
    // createdAt: Date;
    // updatedAt: Date;
}

export interface AutoVoucherStatus {
    status: 'On' | 'Off';
}
export interface ApiBonusGroup {
    pointEarnings: number;
    monetaryEarnings: number;
}

export interface ApiBonus {
    points: number;
    monetaryWorth: number;
}

export interface ApiAutoCheckStatus {
    mmid: number;
}

export const externalLoyaltyApi = {
    getExpiringPoints,
    getVoucherDetails,
    getPreviousMonthBonus,
    getPointsMatrixStructureLevel,
    getPointsSummary,
    getPointsSummaryYearly,
    getTransactionsHistoryByDates,
    getMonthTransactionsHistory,
    getMonthMonetaryTransactionsHistory,
    getPointBalance,
    purchaseOffer,
    getHouseholdMembers,
    getMembershipNames,
    getEligibleForBonus,
    getAutoVoucherStatus,
    activateAutoVoucher,
    deactivateAutoVoucher,
    getConnectedCards,
    deleteConnectedCard,
    addHouseholdMember,
};
