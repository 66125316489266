import { Icon } from '@coop/components';
import {
    FacebookLogoMonoIcon,
    InstagramLogoMonoIcon,
    LinkedinLogoMonoIcon,
    TwitterLogoMonoIcon,
    YoutubeLogoMonoIcon,
} from '@coop/icons';

import styles from './Footer.module.less';

const FooterSocial = () => {
    const socialLinks = [
        {
            url: 'https://www.facebook.com/CoopSverige/',
            icon: FacebookLogoMonoIcon,
            label: 'Coop Sverige Facebook',
        },
        {
            url: 'https://twitter.com/coopsverige',
            icon: TwitterLogoMonoIcon,
            label: 'Coop Sverige Twitter',
        },
        {
            url: 'https://www.instagram.com/coopsverige/',
            icon: InstagramLogoMonoIcon,
            label: 'Coop Sverige Instagram',
        },
        {
            url: 'https://www.youtube.com/user/CoopSverige',
            icon: YoutubeLogoMonoIcon,
            label: 'Coop Sverige YouTube',
        },
        {
            url: 'https://www.linkedin.com/company/coopsverige',
            icon: LinkedinLogoMonoIcon,
            label: 'Coop Sverige LinkedIn',
        },
    ];

    return (
        <div className={styles.FooterSocial}>
            {socialLinks.map((link) => (
                <div key={link.label} className={styles.FooterSocialItem}>
                    <a
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label={link.label}
                    >
                        <Icon
                            icon={link.icon}
                            width={20}
                            height={20}
                            className={styles.FooterSocialIcon}
                            aria-hidden
                        />
                    </a>
                </div>
            ))}
        </div>
    );
};

export default FooterSocial;
