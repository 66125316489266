import { appConfig } from '../../../../appConfig';
import { Cookies } from '../../../../utility/cookies';
import { getAuthToken, getAuthTokenOrDefault } from '../../api/auth/getAuthToken';
import cartApi from '../../api/cart/cartApi';
import directCartApi from '../../api/cart/directCartApi';
import type { ApiAddMultipleRecipesRequest } from '../../api/recipe/recipeModels';
import savedCartsApi from '../../api/savedCarts/savedCartsApi';
import { directStoreApi } from '../../api/store/directStoreApi';
import { timeslotsApi } from '../../api/timeslots/timeslotsApi';
import { DeliveryMode } from '../../store/structureDefinitions/storeState';
import { productFlow } from '../product/productFlow';
import { mapProductToApiFullCartItem } from './mapCartResponses';

const CART_COOKIE_NAME = 'coop-cart';
export const HOME_DELIVERY_MODE = 'homedelivery';
const getCart = async () => {
    const cart = await cartApi.fetchCart();
    return cart;
};

const getCartSimple = async () => {
    const cart = await cartApi.fetchCartSimple();
    return cart;
};

const getCurrentCartId = () => {
    if (appConfig.coopUserSettings.isAuthenticated) {
        return 'current';
    }
    const anonymousCartId = Cookies.read(CART_COOKIE_NAME);
    return anonymousCartId;
};

const emptyCart = () => cartApi.emptyCart();

const buyMultipleRecipes = (request: ApiAddMultipleRecipesRequest) =>
    cartApi.recipes.buyMultipleRecipes(request);

const removeRecipe = (recipeId: string) => cartApi.recipes.removeRecipe(recipeId);

const changeRecipePortions = (recipeId: string, portions: number) =>
    cartApi.recipes.changeRecipePortions(recipeId, portions);

const getCurrentTimeslot = () => timeslotsApi.current();

const selectTimeslot = async (timeslotId: string) => {
    await timeslotsApi.select(timeslotId);
    const currentTimeslot = await timeslotsApi.current();
    return currentTimeslot;
};

const changeItemVariant = (
    cartItemId: string,
    productId: string,
    quantity: number,
    newVariantId: string,
) =>
    cartApi.changeCartItemVariant({
        id: cartItemId,
        productId,
        variantId: newVariantId,
        quantity,
    });

const updateItemQuantity = async (
    cartId: string,
    productId: string,
    variantId: string | undefined,
    quantity: number,
    itemReplaceable?: boolean,
) => {
    const { token, shoppingUserId } = await getAuthTokenOrDefault();

    return directCartApi.updateCartItem(token)(shoppingUserId, cartId, {
        productId,
        variantId,
        quantity,
        itemReplaceable,
    });
};

/** Creates a new cart,
 * if syncWithStoreContext, we will try to update the cart with delivermode / zip / pickup-point
 * */
const createCartAsync = async (
    deliveryMode: DeliveryMode,
    currentZipCode?: string,
    currentPickupPointId?: string,
) => {
    const { token, shoppingUserId } = await getAuthTokenOrDefault();
    const cart = await directCartApi.createCart(shoppingUserId, token);
    const cartId = cart.guid!;
    Cookies.create(CART_COOKIE_NAME, cartId, 70);

    if (deliveryMode === DeliveryMode.homedelivery && currentZipCode) {
        const store = await directStoreApi.getStoreByPostCode(currentZipCode!);
        await directCartApi.updatePostCode(
            shoppingUserId,
            cartId,
            currentZipCode!,
            store.code,
            true,
            token,
        );
        await directCartApi.setDeliveryMode(shoppingUserId, cartId, HOME_DELIVERY_MODE, token);
    }
    if (deliveryMode === DeliveryMode.pickup && currentPickupPointId) {
        const pointOfService = await directStoreApi.getPointOfService(currentPickupPointId!);
        if (!pointOfService?.pickupDeliveryModes?.length) {
            throw new Error('No deliverymodes!');
        }
        await directCartApi.setPointOfService(
            shoppingUserId,
            cartId,
            currentPickupPointId!,
            true,
            token,
        );
        await directCartApi.setDeliveryMode(
            shoppingUserId,
            cartId,
            pointOfService.pickupDeliveryModes[0].code,
            token,
        );
    }

    if (deliveryMode) {
        return directCartApi.getCart(shoppingUserId, cartId, token);
    }
    return cart;
};

const getItemSubstitutes = async (rowNumber: string, storeId: string) => {
    const substituteProducts = await cartApi.recipes.getProductSubstitutes(rowNumber);

    const incompleteProductIds = substituteProducts.map((product) => {
        return product.id;
    });

    const products = await productFlow.getProductsByIds([...incompleteProductIds], storeId);
    return products;
};

const substituteProduct = async (cartItemId: string, newProductId: string, storeId: string) => {
    const subsituteResponse = await cartApi.recipes.substituteProduct(cartItemId, newProductId);

    const fullProduct = await productFlow.getProductById(
        subsituteResponse.productIdentifier,
        storeId,
    );

    const cartItem = fullProduct
        ? mapProductToApiFullCartItem(subsituteResponse, fullProduct)
        : null;

    return cartItem;
};

const applyVoucher = (id: string) => cartApi.voucher.add(id);

const removeVoucher = (id: string) => cartApi.voucher.remove(id);

const changeReplaceability = async (
    productId: string,
    variantId: string | undefined,
    quantity: number, // needs to be there since we have one endpoint that does not like empty quantity
    replaceable: boolean,
) => {
    const cartId = await getCurrentCartId();
    if (!cartId) {
        throw new Error('No cart id');
    }
    const { token, shoppingUserId } = await getAuthTokenOrDefault();
    const updatedItem = await directCartApi.updateCartItem(token)(shoppingUserId, cartId, {
        productId,
        variantId,
        quantity,
        itemReplaceable: replaceable,
    });
    return updatedItem;
};

const setItemsReplaceability = (replaceable: boolean) =>
    cartApi.setItemsReplaceability({ replaceAll: replaceable });

const addToCartFromOrder = async (orderId: string) => {
    const { token, shoppingUserId } = await getAuthToken();
    const cartId = await getCurrentCartId();
    if (!cartId) {
        throw new Error('No cart id');
    }
    const response = await directCartApi.addToCartFromOrder(token)(shoppingUserId, cartId, orderId);
    return response;
};

const restoreSavedCart = async (savedCartId: string) => {
    const { token, shoppingUserId } = await getAuthToken();
    const cartId = await getCurrentCartId();
    if (!cartId) {
        throw new Error('No cart id');
    }
    const response = await savedCartsApi.restoreSavedCart(token)(
        shoppingUserId,
        cartId,
        savedCartId,
    );
    return response;
};

const validateAssortment = async () => {
    const { token, shoppingUserId } = await getAuthToken();
    return cartApi.validateAssortment(token)(shoppingUserId);
};

const addItemsBulk = async (
    cartId: string,
    items: {
        productId: string;
        variantId?: string;
        quantity: number;
        replaceable?: boolean;
    }[],
) => {
    const { token, shoppingUserId } = await getAuthTokenOrDefault();
    const response = await directCartApi.bulkEntries(token)(
        shoppingUserId,
        cartId,
        items.map((item) => ({
            productCode: item.productId,
            varianceCode: item.variantId,
            quantity: item.quantity,
            replaceable: item.replaceable ?? true,
        })),
    );
    return response;
};

export const currentCartFlow = {
    getCurrentCartId,
    getCart,
    getCartSimple,
    createCartAsync,
    emptyCart,
    removeRecipe,
    buyMultipleRecipes,
    addItemsBulk,
    changeRecipePortions,
    getCurrentTimeslot,
    selectTimeslot,
    changeItemVariant,
    updateItemQuantity,
    getItemSubstitutes,
    substituteProduct,
    applyVoucher,
    removeVoucher,
    changeReplaceability,
    setItemsReplaceability,
    restoreSavedCart,
    addToCartFromOrder,
    validateAssortment,
};
