import { useElementEvent } from '@coop/hooks';
import _ from 'underscore';

export const useDocumentPropertyHeightSetter = (
    htmlPropertyNameToSet: `--${string}`,
    ref: React.RefObject<HTMLElement>,
    enabled: boolean = true,
) => {
    const handleResize = _.throttle(() => {
        if (ref.current) {
            const { height } = ref.current.getBoundingClientRect();
            document.documentElement.style.setProperty(htmlPropertyNameToSet, `${height}px`);
        }
    }, 200);

    useElementEvent(window, 'resize', handleResize, true, undefined, enabled);
};
