import classNames from 'classnames';
import type { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { ga4NavigationTracking } from '../../../common/tracking/ga4/navigation';
import { isAriaCurrentPage } from '../../../common/utils/accessibilityUtils';

export interface SiteNavigationItem {
    isActive: boolean;
    name: string;
    url: string;
    contentLinkId: number;
}

interface FeaturedNavigationProps {
    items: SiteNavigationItem[];
}

const FeaturedNavigation: FC<React.PropsWithChildren<FeaturedNavigationProps>> = (props) => {
    const { pathname } = useLocation();

    return (
        <div className="Navigation">
            <nav aria-label="Genvägar">
                <ul className="Navigation-list">
                    {props.items.map((item) => {
                        const ariaCurrentPage =
                            item.isActive && isAriaCurrentPage(item.url, pathname);
                        return (
                            <li
                                className={classNames(
                                    'Navigation-item',
                                    item.isActive && 'is-active',
                                )}
                                key={item.name}
                            >
                                <a
                                    aria-current={ariaCurrentPage}
                                    href={item.url}
                                    data-test={`mainnav-${item.name
                                        .toLowerCase()
                                        .replace(' & ', '-')}`}
                                    onClick={(e) => {
                                        ga4NavigationTracking.headerNavigationClick(
                                            item.name,
                                            e.currentTarget.href,
                                        );
                                    }}
                                >
                                    {item.name}
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </nav>
        </div>
    );
};

export default FeaturedNavigation;
