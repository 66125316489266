import type { ReactNode } from 'react';

type SkipLinkContainerProps = {
    skipTriggerSlot?: ReactNode;
    endSlot?: ReactNode;
    children: ReactNode;
};

const SkipLinkLayout = (props: SkipLinkContainerProps) => {
    return (
        <div className="u-isolate">
            <div className="u-zIndex2 u-posRelative u-noPrint">{props.skipTriggerSlot}</div>
            <div className="u-zIndex1 u-posRelative">{props.children}</div>
            {props.endSlot}
        </div>
    );
};

export default SkipLinkLayout;
