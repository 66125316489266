import { CoopSwiperBreakpoints } from '../../../../utility/coopSwiperBreakpoints';
import ItemGrid from '../../../ecommerce/components/ItemGrid/ItemGrid';
import { useBreakpointsSidebar } from '../../../ecommerce/hooks/useBreakpoints';
import FooterColumn from './FooterColumn';

const FooterColumns = (props: Pick<FrontendFooter, 'columns'>) => {
    const { cellsInRow } = useBreakpointsSidebar(CoopSwiperBreakpoints.AREA_FOOTER_GRID);

    return (
        <ItemGrid
            gridClasses="Grid--gutterH Grid--gutterVsm u-lg-flexJustifyCenter"
            cellsInRow={cellsInRow}
            items={props.columns}
            renderItem={(column) => <FooterColumn column={column} />}
            itemUniqueKey={(column, index) => `${column.headerLink?.contentId}_${index}`}
        />
    );
};

export default FooterColumns;
