import { OverlayLoader } from '@coop/components';
import { UserIcon } from '@coop/icons';
import * as React from 'react';

import { FlyinNavigation } from '../../../common/components/atoms/FlyInNavigation';
import { FlyIn } from '../../../common/components/atoms/Modal';
import useModal from '../../../common/hooks/useModal';
import { FlyInType } from '../../../common/store/structureDefinitions/modalState';
import HeaderIconButton from '../HeaderIconButton/HeaderIconButton';
import MyCoopProfileMenu from './MyCoopProfileMenu';

const MyCoopProfileFlyIn = () => {
    const { open, isOpen, close } = useModal(FlyInType.MyProfileMenu);

    return (
        <FlyIn
            isOpen={isOpen}
            close={close}
            sizePx={375}
            renderTrigger={(ref) => (
                <HeaderIconButton
                    ref={ref}
                    icon={UserIcon}
                    label="Mitt coop"
                    testSelector="user"
                    onClick={open}
                    theme="bordered"
                />
            )}
        >
            <FlyinNavigation onClose={close}>
                <React.Suspense fallback={<OverlayLoader isLoading />}>
                    <MyCoopProfileMenu />
                </React.Suspense>
            </FlyinNavigation>
        </FlyIn>
    );
};

export default MyCoopProfileFlyIn;
