'use client';

/* eslint-disable react/jsx-props-no-spreading */
import { AccessibleIcon as RadixAccessibleIcon } from '@radix-ui/react-accessible-icon';
import type { ComponentProps } from 'react';

import Icon from './Icon';

const AccessibleIcon = (props: ComponentProps<typeof Icon> & { label: string }) => {
    const { label, ...iconProps } = props;
    return (
        <RadixAccessibleIcon label={label}>
            <Icon {...iconProps} />
        </RadixAccessibleIcon>
    );
};

export default AccessibleIcon;
