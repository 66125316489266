import classNames from 'classnames';
import type { FC } from 'react';
import { Img } from 'react-image';

interface ImageProps {
    src: string;
    alt?: string;
    title?: string;
    cssClass?: string;
    wrapperClassName?: string;
    lazy?: boolean;
    srcSet?: string;
    unloader?: JSX.Element;
    loader?: JSX.Element;
    width?: string | number;
    height?: string | number;
    onClick?: React.MouseEventHandler<HTMLImageElement>;
    ariaLabel?: string;
}

const FallbackImg = (props: ImageProps) => {
    return <img className={props.cssClass} alt="" />;
};

const Image: FC<ImageProps> = (props: ImageProps) => {
    if (window.isPrerenderCrawler) {
        return (
            <div className={classNames('u-heightAll', props.wrapperClassName)}>
                <img
                    className={props.cssClass}
                    src={props.src}
                    alt={props.alt || ''}
                    srcSet={props.srcSet}
                    draggable="false"
                    aria-label={props.ariaLabel}
                />
            </div>
        );
    }

    return (
        <Img
            onClick={props.onClick}
            alt={props.alt}
            title={props.title}
            src={props.src}
            srcSet={props.srcSet}
            decode={false}
            draggable="false"
            className={props.cssClass}
            width={props.width}
            height={props.height}
            loading={props.lazy ? 'lazy' : undefined}
            unloader={props.unloader || FallbackImg(props)}
            loader={props.loader || FallbackImg(props)}
        />
    );
};

export default Image;
