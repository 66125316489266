/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import { splitCustomObjectFromIntersection } from '@coop/utils';
import * as React from 'react';
import type { TextFieldProps } from 'react-aria-components';

import { TextField } from '../TextField';

interface TextInputCustomProps {
    label?: string;
    helperText?: string;
    validationText?: string;
    disabledReason?: React.ReactNode;
    postfixSlot?: React.ReactNode;
    /**
     * In certain cases it is needed to hide the indicator that a field is required or optional.
     * It might depend on the context of the form and how many required fields there is versus optional ones.
     * If most fields in a form are required - show indicator for optional ones.
     * If most fields in a form are optional - show indicator for required ones.
     * By default the label indicator is shown.
     * @default "label"
     */
    necessityIndicator?: 'label' | 'none';
}

// Override React Aria's TextFieldProps to add comments for some props
interface TextFieldOverridenProps {
    /**
     * @deprecated Use helperText instead with a correct UX copy. Placeholder will be removed from TextInput for A11Y reasons.
     * */
    placeholder?: string;

    /** Makes a field required for A11Y purposes. It does not add a visual cue when necessityIndicator is set to 'none'. */
    isRequired?: boolean;

    /** Use disabledReason instead. It will make the field read only (focusable) and provide the text why its disabled in the help text label.  */
    isDisabled?: never;
}

const TextInput = React.forwardRef<
    HTMLInputElement,
    TextInputCustomProps & TextFieldProps & TextFieldOverridenProps
>((props, ref) => {
    const { custom: customProps, rest: textInputProps } = splitCustomObjectFromIntersection<
        TextInputCustomProps,
        TextFieldProps
    >(props, {
        label: props.label,
        helperText: props.helperText,
        validationText: props.validationText,
        disabledReason: props.disabledReason,
        postfixSlot: props.postfixSlot,
        necessityIndicator: props.necessityIndicator,
    });

    return (
        <TextField.Root
            {...textInputProps}
            isReadOnly={!!customProps.disabledReason}
            // We mostly use the inputs with React Hook Form, so the validation behavior must be set to aria
            validationBehavior={textInputProps.validationBehavior ?? 'aria'}
        >
            <TextField.TopContainer>
                <TextField.Label
                    label={customProps.label}
                    isRequired={textInputProps.isRequired}
                    necessityIndicator={props.necessityIndicator}
                />
                <TextField.HelperText
                    helperText={customProps.helperText}
                    disabledReason={customProps.disabledReason}
                />
            </TextField.TopContainer>

            <TextField.InputContainer>
                <TextField.Input ref={ref} />
                {props.postfixSlot && <TextField.Postfix>{props.postfixSlot}</TextField.Postfix>}
            </TextField.InputContainer>

            {customProps.validationText && (
                <TextField.BottomContainer>
                    <TextField.ValidationError validationText={customProps.validationText} />
                </TextField.BottomContainer>
            )}
        </TextField.Root>
    );
});

export default TextInput;
