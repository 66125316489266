import type { FC } from 'react';
import { createContext, useContext, useMemo } from 'react';

export const enum SiteContextType {
    /** Whole visible page container (except the Header) */
    Global,

    /** Main content of a page */
    PageContent,

    /** Side content, i.e. sidebar menu */
    Side,

    Header,
    Footer,

    Modal,
}

interface ISiteContext {
    siteContextType?: SiteContextType;
}

const SiteContext = createContext<ISiteContext>({ siteContextType: undefined });

export const useSiteContext = () => {
    const featureContext = useContext(SiteContext);
    return featureContext;
};

export const useIsInsideModalContext = () => {
    const context = useContext(SiteContext);

    return context?.siteContextType === SiteContextType.Modal;
};

export const SiteContextProvider: FC<React.PropsWithChildren<{ siteContext: SiteContextType }>> = (
    props,
) => {
    const value = useMemo<ISiteContext>(
        () => ({ siteContextType: props.siteContext }),
        [props.siteContext],
    );

    return <SiteContext.Provider value={value}>{props.children}</SiteContext.Provider>;
};
