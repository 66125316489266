import { useQuery } from '@tanstack/react-query';

import loyaltyFlow from '../../../common/flow/loyaltyFlow';
import { pointsQueryKeys } from '../pointsQueryKeys';

export const useHouseholdPointBalance = (enabled: boolean = true) => {
    const query = useQuery({
        queryKey: pointsQueryKeys.householdMonetaryBalance,
        queryFn: loyaltyFlow.getPointBalance,
        retry: 0,
        enabled,
    });

    return query;
};
