import { useQuery } from '@tanstack/react-query';

import localContentApi from '../../../episerver/api/localContentApi';
import { myCoopProfileMenuKeys } from '../../../myCoop/myCoopQueries';

export const useMyCoopProfileMenu = () => {
    const query = useQuery({
        queryKey: myCoopProfileMenuKeys.all,
        queryFn: async () => {
            const pages = await localContentApi.getMyCoopProfileMenu();
            return pages;
        },
        staleTime: 1000 * 60 * 15, // 15 minutes
    });

    return query;
};
