import { Helpers } from '../../../../utility/helpers';
import { useAppSelector } from '../../../common/hooks/useThunkDispatch';
import { cartSelectors } from '../../../common/selectors/cartSelectors';
import cartItemsSelectors from '../../selectors/cartItemsSelectors';

const getCartSummaryAccessibilityText = (totalCartQuantity: number, totalPrice?: number) => {
    let cartCountLabel;
    if (totalCartQuantity === 0) {
        cartCountLabel = 'Din varukorg är tom';
    }
    if (totalCartQuantity === 1) {
        cartCountLabel = 'Totalt har du en vara i varukorgen';
    }

    if (totalCartQuantity > 1) {
        cartCountLabel = `Totalt har du ${totalCartQuantity} varor i varukorgen`;
    }

    let totalPriceLabel;
    if (totalPrice) {
        totalPriceLabel = `Totalpris är ${Helpers.toEcommercePriceWithCurrency(totalPrice)}`;
    }

    return `${[cartCountLabel, totalPriceLabel].filter(Boolean).join('. ')}`;
};

interface CartSummaryAccessibilityLoggerProps {
    totalPrice?: number; // when we will be able to display cart value in CnCTrigger, this should be read from Redux instead
}

const CartSummaryAccessibilityLogger = (props: CartSummaryAccessibilityLoggerProps) => {
    const totalCartQuantity = useAppSelector(cartItemsSelectors.selectCartQuantity);
    const cartIsInitialized = useAppSelector(cartSelectors.isCartInitialized);
    const cartIsLoading = useAppSelector((state) => state.cart.cartLoading.isLoading);

    // Update the text when cart is done loading, until then state will preseve the previous text
    const text =
        cartIsInitialized &&
        !cartIsLoading &&
        getCartSummaryAccessibilityText(totalCartQuantity, props.totalPrice);

    return (
        <span aria-live="polite" aria-atomic="true" className="u-hiddenVisually">
            {text}
        </span>
    );
};

export default CartSummaryAccessibilityLogger;
