import { failSilently } from '../../utils/failSilently';
import { sendGA4Event } from '../shared/sendGa4Event';

const walletInteraction = (
    type: 'wallet open' | 'wallet close' | 'wallet click',
    walletLevel1: string | undefined,
    walletLevel2: string | undefined,
    clickText: string | undefined,
    clickUrl: string | undefined,
    pageType: string,
) => {
    const event: WalletInteractionEvent = {
        event: 'interaction',
        type,
        wallet_level_1: walletLevel1 || 'not selected',
        wallet_level_2: walletLevel2 || 'not selected',
        click_text: clickText || 'not selected',
        click_url: clickUrl || 'not selected',
        page_type: pageType,
    };

    sendGA4Event(event);
};

const viewBonus = (type: BonusImpressionType) => (pageType: string) => {
    const event: SendBonusImpressionEvent = {
        event: 'impression',
        type,
        page_type: pageType,
    };

    sendGA4Event(event);
};

const chartInteraction = (clickText: string, pageType: string) => {
    const event: SavedLastYearInteractionEvent = {
        event: 'interaction',
        type: 'saved bonus',
        click_text: clickText,
        click_url: 'not selected',
        page_type: pageType,
    };

    sendGA4Event(event);
};

const navigateBonusInteraction = (
    type: BonusInteractionEventType | null,
    clickText: string,
    clickUrl: string | undefined,
    pageType: string,
) => {
    const event: BonusInteractionEvent = {
        event: 'interaction',
        click_text: clickText,
        click_url: clickUrl || 'not selected',
        page_type: pageType,
    };
    if (type) {
        event.type = type;
    }
    sendGA4Event(event);
};

const viewSavedBonus = (pageType: string) => {
    const event: SavedBonusImpressionEvent = {
        event: 'impression',
        type: 'saved bonus',
        page_type: pageType,
    };

    sendGA4Event(event);
};

export default {
    walletInteraction: failSilently(walletInteraction),
    viewHowDoesItWork: failSilently(viewBonus('how the bonus works')),
    viewLastMonthBonus: failSilently(viewBonus('last month bonus')),
    chartInteraction: failSilently(chartInteraction),
    viewSavedBonus: failSilently(viewSavedBonus),
    navigation: {
        infoSymbolInteraction: failSilently((pageType: string) =>
            navigateBonusInteraction(null, 'info symbol', undefined, pageType),
        ),
        bonusInteraction: failSilently((clickText: string, clickUrl: string, pageType: string) =>
            navigateBonusInteraction('usable bonus', clickText, clickUrl, pageType),
        ),
    },
};
