import type React from 'react';

import type { SiteSkipLinkType } from './SiteSkipLinks';
import SkipButton from './SkipButton';

const SkipLink = (props: {
    href: `#${SiteSkipLinkType}`;
    align?: 'start' | 'center';
    children: React.ReactNode;
}) => {
    return (
        <SkipButton align={props.align} asChild>
            <a
                href={props.href}
                onClick={(e) => {
                    // Focus and scroll programmatically to avoid adding a #href to the current URL
                    e.preventDefault();
                    const target = document.querySelector<HTMLElement>(props.href);
                    target?.focus();
                    target?.scrollIntoView();
                }}
            >
                {props.children}
            </a>
        </SkipButton>
    );
};

export default SkipLink;
