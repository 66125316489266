import type { MyCoopProfileMenuItem } from '../../../episerver/api/localContentApi';

export const getTopActivePage = (page: MyCoopProfileMenuItem, activePageId: number) => {
    if (page.id === activePageId) {
        return page.id;
    }

    if (page.menuItemChildren && page.menuItemChildren.length > 0) {
        if (page.menuItemChildren.some((child) => !!getTopActivePage(child, activePageId))) {
            return page.id;
        }
    }

    return undefined;
};
