import { Icon } from '@coop/components';
import { CoopLogoIcon } from '@coop/icons';

import styles from './Footer.module.less';

const FooterContact = (props: Pick<FrontendFooter, 'bottomLinks'>) => {
    return (
        <>
            <div className={styles.FooterLogo}>
                <a href="/" aria-label="Till startsidan">
                    <Icon
                        icon={CoopLogoIcon}
                        height={26}
                        className={styles.FooterLogoImage}
                        aria-hidden
                    />
                </a>
            </div>

            <p className={styles.FooterContact}>
                <span>
                    Coop Sverige{' '}
                    <a
                        className={styles.FooterContactLink}
                        href="https://www.google.se/maps/place/Coop+Huvudkontor/59.3598297,17.9783251,15z/data=!4m5!3m4!1s0x0:0x824f828e19b3770c!8m2!3d59.3598297!4d17.9783251"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        171 88 Solna
                    </a>
                    , Telefon: 010-742 00 00,&nbsp;
                    {/* dont break word on the organisation number */}
                    <span style={{ display: 'inline-block' }}>Org.nr: 556710-5480</span>
                </span>
                {props.bottomLinks.map((bottomLink) => (
                    <span>
                        <a className={styles.FooterContactLink} href={bottomLink.url}>
                            {bottomLink.name}
                        </a>
                    </span>
                ))}
            </p>
        </>
    );
};

export default FooterContact;
