import getLocalApiAxiosClient from '../../common/api/clients/localApiAxiosClient';

export interface MyCoopProfileMenuItem {
    id: number;
    name: string;
    url: string;
    icon: string;
    linkType: PageShortcutType;
    menuItemChildren: MyCoopProfileMenuItem[];
}

const localContentApi = {
    getMyCoopProfileMenu: async () => {
        const response = await getLocalApiAxiosClient().get<MyCoopProfileMenuItem[]>(
            '/content/myCoop/profileMenu',
        );
        return response.data;
    },
};

export default localContentApi;
