import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { appConfig } from '../../../../appConfig';
import { ga4NavigationTracking } from '../../../common/tracking/ga4/navigation';
import { isAriaCurrentPage } from '../../../common/utils/accessibilityUtils';
import { useIsB2BRoute } from '../../../common/utils/b2bUtils';
import useReactRouting from '../../../ecommerce/hooks/useReactRouting';
import styles from './UserTypeModeSwitch.module.less';

interface UserTypeModeSwitchProps {
    theme: 'white' | 'green';
}

/**
 * Switch for B2B / B2C sites
 */
const UserTypeModeSwitch = (props: UserTypeModeSwitchProps) => {
    const { push } = useReactRouting();
    const isB2BRoute = useIsB2BRoute();
    const { pathname } = useLocation();

    return (
        <ul
            className={classNames(
                styles.UserModeSwitch,
                props.theme === 'white' && styles['UserModeSwitch--white'],
                props.theme === 'green' && styles['UserModeSwitch--green'],
            )}
        >
            <li
                className={classNames(
                    styles.UserMode,
                    styles['UserMode--topIndicator'],
                    !isB2BRoute && styles.UserModeActive,
                )}
            >
                <a
                    href={appConfig.coopSettings.b2c.startPage.url}
                    onClick={(e) => {
                        e.preventDefault();
                        ga4NavigationTracking.headerNavigationClick(
                            e.currentTarget.innerText,
                            e.currentTarget.href,
                        );
                        push(e.currentTarget.href);
                    }}
                    aria-current={isAriaCurrentPage(
                        appConfig.coopSettings.b2c.startPage.url,
                        pathname,
                    )}
                >
                    Privat
                </a>
            </li>
            {!!appConfig.coopSettings.b2b.startPage && (
                <li
                    className={classNames(
                        styles.UserMode,
                        styles['UserMode--topIndicator'],
                        isB2BRoute && styles.UserModeActive,
                    )}
                >
                    <a
                        href={appConfig.coopSettings.b2b.startPage.url}
                        onClick={(e) => {
                            e.preventDefault();
                            ga4NavigationTracking.headerNavigationClick(
                                e.currentTarget.innerText,
                                e.currentTarget.href,
                            );
                            push(e.currentTarget.href);
                        }}
                        aria-current={isAriaCurrentPage(
                            appConfig.coopSettings.b2b.startPage.url,
                            pathname,
                        )}
                    >
                        Företag
                    </a>
                </li>
            )}
        </ul>
    );
};
export default UserTypeModeSwitch;
