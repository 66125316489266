import { Helpers } from '../../../utility/helpers';
import type { OrderEntry } from '../models/domain/order';

export const orderEntryEqualsPredicate =
    (orderEntry: OrderEntry) => (productId: string, variantId?: string) => {
        return (
            orderEntry.product.code === productId &&
            (!variantId || orderEntry.variance?.code === variantId)
        );
    };

export const orderEntryItemEqualsPredicate =
    <TItem extends { productId: string; variantId?: string }>(item: TItem) =>
    (orderEntry: OrderEntry) => {
        return orderEntryEqualsPredicate(orderEntry)(item.productId, item.variantId);
    };

export const convertToFriendlyDateString = (
    date?: Date,
    lowerCase = false,
    comma = false,
    allowYesterday = false,
) => {
    if (!date) return '';

    const weekdays = ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'lördag'];
    const months = [
        'jan',
        'feb',
        'mar',
        'apr',
        'maj',
        'jun',
        'jul',
        'aug',
        'sep',
        'okt',
        'nov',
        'dec',
    ];

    const day = date.getDate();
    const weekday = weekdays[date.getDay()];
    const month = months[date.getMonth()];

    const today: boolean = Helpers.dateIsToday(date);
    const tommorow: boolean = Helpers.dateIsTomorrow(date);
    const yesterday: boolean = Helpers.dateIsYesterday(date);

    if (today) {
        return lowerCase ? 'idag' : 'Idag';
    }

    if (tommorow) {
        return lowerCase ? 'imorgon' : 'Imorgon';
    }

    if (yesterday && allowYesterday) {
        return lowerCase ? 'igår' : 'Igår';
    }

    return lowerCase
        ? `${weekday.toLowerCase()}${comma ? ',' : ''} ${day} ${month}`
        : `${weekday}, ${day} ${month}`;
};

export const convertTimeWindowToFriendlyString = (window: {
    startTime?: Date;
    endTime?: Date;
}): string => {
    const start = window.startTime;
    const end = window.endTime;

    let startTime = '';

    if (start) {
        const sHours = start !== null ? start.getHours().toString().padStart(2, '0') : '00';
        const sMins = start !== null ? start.getMinutes().toString().padStart(2, '0') : '00';

        startTime = `${sHours}.${sMins}`;
    }

    let endTime = '';

    if (end) {
        const eHours = end !== null ? end.getHours().toString().padStart(2, '0') : '00';
        const eMins = end !== null ? end.getMinutes().toString().padStart(2, '0') : '00';

        endTime = `${eHours}.${eMins}`;
    }

    return startTime && endTime ? `${startTime}–${endTime}` : '';
};

export const orderTimelineStepAccessibleName = (
    item: { accessibleTitle?: string; description?: string; title: string; checked: boolean },
    isActive: boolean,
    orderStatus: OrderStatus,
) => {
    const title = item.accessibleTitle || item.title;

    if (isActive) {
        return `${title} - Pågår. ${item.description || ''}`;
    }
    if (item.checked) {
        return `${title} - ${orderStatus === 'picked' ? 'Klart' : 'Klar'}. ${
            item.description || ''
        }`;
    }

    return `${title}. ${item.description || ''}`;
};
