import type { FC } from 'react';

import ComponentPortals from '../common/components/molecules/ComponentPortals';
import CommonLayout from './components/CommonLayout';
import type { GlobalHeaderDOMProps } from './components/GlobalHeader';
import GlobalHeader from './components/GlobalHeader';

interface BaseLayoutProps {
    stayAfterLoginLogoutRedirect?: boolean;
}

/**
 * Layout for every page that is not using a more specific Layout, like i.e. EcommerceLayout
 */
const BaseLayout: FC<React.PropsWithChildren<BaseLayoutProps>> = (props) => {
    return (
        <CommonLayout>
            <ComponentPortals
                cmpName="Header"
                render={(domProps: GlobalHeaderDOMProps) => (
                    <GlobalHeader
                        preHeaderText={domProps.preHeaderText}
                        featuredNavigation={domProps.featuredNavigation}
                        currentPage={domProps.currentPage}
                        initialSearchCategory={domProps.initialSearchCategory}
                        searchDisabled={domProps.searchDisabled}
                        useToggledSearch={domProps.useToggledSearch}
                        headerTheme={domProps.headerTheme}
                        preHeaderTheme={domProps.preHeaderTheme}
                        stayAfterLoginLogoutRedirect={props.stayAfterLoginLogoutRedirect}
                    />
                )}
            />

            {props.children}
        </CommonLayout>
    );
};

export default BaseLayout;
