import { Button, ChipButton, ConfirmInfo, Snackbar, TextInput } from '@coop/components';
import { useMutation } from '@tanstack/react-query';
import { addSeconds, intervalToDuration } from 'date-fns';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { appConfig } from '../../../../appConfig';
import { Helpers } from '../../../../utility/helpers';
import {
    cancelImpersonation,
    getCachedAuthToken,
    impersonateUser,
} from '../../../common/api/auth/getAuthToken';

const ImpersonationNotice = () => {
    const [isOpen, setIsOpen] = useState(false);
    const signout = async () => {
        await cancelImpersonation();
        window.location.reload();
    };
    if (appConfig.coopUserSettings.allowImpersonate) {
        return <StartImpersonationBar />;
    }
    if (!appConfig.coopUserSettings.isImpersonating) {
        return null;
    }
    const identity = getCachedAuthToken();
    if (!identity?.impersonating) {
        return null;
    }
    // lets expire sooner to be safe
    const expireTime = addSeconds(new Date(identity.expires), -60);
    return (
        <>
            <Snackbar
                size={48}
                actionSlot={
                    <ChipButton
                        theme="greenCta"
                        size={32}
                        onClick={() => setIsOpen((prev) => !prev)}
                    >
                        Info
                    </ChipButton>
                }
                style={{
                    marginBottom: 24,
                }}
            >
                {appConfig.coopUserSettings.profile?.email}
            </Snackbar>
            <ConfirmInfo
                show={isOpen}
                heading="Du imiterar en annan person."
                message={
                    <div className="u-textCenter">
                        Du imiterar en person med id:
                        <br /> {appConfig.coopUserSettings.userId}
                        <div className="u-flex u-flexJustifySpaceBetween u-flexAlignCenter u-marginT ">
                            <Button onClick={signout}>Avsluta</Button>
                            <CountDown end={expireTime} />
                        </div>
                    </div>
                }
                close={() => setIsOpen(false)}
            />
            <ImpersonationExpiration expires={expireTime} signout={signout} />
        </>
    );
};

const StartImpersonationBar = () => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <Snackbar
                size={48}
                actionSlot={
                    <ChipButton
                        theme="greenCta"
                        size={32}
                        onClick={() => setIsOpen((prev) => !prev)}
                    >
                        Öppna
                    </ChipButton>
                }
                style={{
                    marginBottom: 24,
                }}
            >
                Logga in som kund
            </Snackbar>
            <ConfirmInfo
                show={isOpen}
                heading="Logga in som en användare"
                message={<Impersonate />}
                close={() => setIsOpen(false)}
            />
        </>
    );
};

const Impersonate = () => {
    const { handleSubmit, control, getValues } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: {
            identifier: '',
        },
    });
    const onSubmit = () => {
        const formValues = getValues();
        const { identifier } = formValues;
        const isEmail = Helpers.validateEmail(identifier);
        impersonateMutation.mutate({
            userToImpersonate: identifier,
            userIdentifierType: isEmail ? 'Email' : 'Id',
        });
    };
    const impersonateMutation = useMutation({
        mutationFn: async (params: { userToImpersonate: string; userIdentifierType: string }) =>
            impersonateUser(params),
        onSuccess: () => {
            window.location.reload();
        },
    });

    return (
        <div>
            <p>Skriv in e-postadressen eller användarens id för att logga in som den personen.</p>
            <p>
                <strong>Obs! Allt som görs loggas!</strong>
            </p>
            <form
                className="u-flex u-flexDirectionColumn u-heightAll"
                onSubmit={handleSubmit(onSubmit)}
            >
                <Controller
                    render={({ field, fieldState }) => (
                        <TextInput
                            label="Id / E-postadress"
                            isRequired
                            necessityIndicator="none"
                            value={field.value}
                            name={field.name}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            ref={field.ref}
                            validationText={fieldState.error?.message}
                            isInvalid={!!fieldState.error}
                        />
                    )}
                    name="identifier"
                    rules={{
                        required: true,
                    }}
                    control={control}
                />
                {impersonateMutation.isSuccess && (
                    <div>Du imiterar nu {impersonateMutation.data.email}</div>
                )}

                {impersonateMutation.isError && <div>Åh, misslyckades imitera användaren.</div>}
                <Button
                    type="submit"
                    disabled={impersonateMutation.isSuccess || impersonateMutation.isPending}
                >
                    Logga in
                </Button>
            </form>
        </div>
    );
};

const CountDown = (props: { end: Date }) => {
    const [time, setTime] = useState(new Date());
    const { hours, minutes, seconds } = intervalToDuration({
        start: time,
        end: props.end,
    });
    useEffect(() => {
        const interval = setInterval(() => {
            setTime(new Date());
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);
    const zeroPad = (num: number, places: number) => String(num).padStart(places, '0');
    return (
        <span>{`${zeroPad(hours || 0, 2)}:${zeroPad(minutes || 0, 2)}:${zeroPad(
            seconds || 0,
            2,
        )}`}</span>
    );
};

const ImpersonationExpiration = (props: { expires: Date; signout: () => void }) => {
    useEffect(() => {
        const timeout = setTimeout(props.signout, Number(props.expires) - Date.now());
        return () => {
            clearTimeout(timeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.expires]);

    return null;
};

export default ImpersonationNotice;
