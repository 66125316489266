import { ChipIconButton } from '@coop/components';
import { ArrowLeftIcon, CloseIcon } from '@coop/icons';
import { useEffect, useRef } from 'react';

interface FlyInNavigationProps {
    backNavigationAllowed?: boolean;
    onBack?: () => void;
    onClose: () => void;
    autoFocus?: boolean;
}

export const FlyinNavigation = ({
    backNavigationAllowed = false,
    ...props
}: React.PropsWithChildren<FlyInNavigationProps>) => {
    const ref = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
        if (ref.current && props.autoFocus) {
            ref.current.focus();
        }
    }, [props.autoFocus, ref]);

    return (
        <>
            {/* children are on top, to prevent z-index wars - when elements have the same zindex (or default), the DOM order determines whoch one is on top */}
            {/* both close and back are absolute here, so the order of children in JSX doesnt matter */}
            {props.children}

            {backNavigationAllowed && props.onBack && (
                <div className="FlyIn-back">
                    <ChipIconButton
                        tabIndex={0}
                        theme="whiteGreen"
                        size={32}
                        onClick={props.onBack}
                        icon={ArrowLeftIcon}
                        label="Tillbaka"
                    />
                </div>
            )}
            <div className="FlyIn-close">
                <ChipIconButton
                    ref={ref}
                    icon={CloseIcon}
                    theme="whiteGreen"
                    onClick={props.onClose}
                    size={32}
                    label="Stäng"
                />
            </div>
        </>
    );
};

export default FlyinNavigation;
