import { Button, FullOverlayLoader } from '@coop/components';
import type { FC } from 'react';
import { useEffect } from 'react';

import { appConfig } from '../../../../appConfig';
import { ga4NavigationTracking } from '../../../common/tracking/ga4/navigation';
import { ga4UserTracking } from '../../../common/tracking/ga4/user';
import type { MyCoopProfileMenuItem } from '../../../episerver/api/localContentApi';
import { useCurrentEpiPage } from '../../../episerver/components/hooks/useCurrentEpiPage';
import { useEligibleForBonus } from '../../../member/pointshop/hooks/useEligibleForBonus';
import ProfileMenuList from '../../../myCoop/profileMenu/ProfileMenuList';
import MenuHeaderBonusSystem from './MenuHeaderBonusSystem';
import { useMyCoopProfileMenu } from './MyCoopProfileFlyIn.hooks';
import styles from './MyCoopProfileFlyIn.module.less';

interface MyCoopProfileMenuProps {
    handleListItemClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}
const getParentName = (menuItems: MyCoopProfileMenuItem[] | undefined, selectedPageId: number) => {
    if (menuItems === undefined) return null;

    const parentMenu = menuItems.find((menu) =>
        menu?.menuItemChildren?.some((child) => child.id === selectedPageId),
    );

    return parentMenu ? parentMenu.name : null;
};

const MyCoopProfileMenu: FC<MyCoopProfileMenuProps> = (props) => {
    const {
        data: pages,
        isSuccess: menuIsSuccess,
        isLoading: menuIsLoading,
    } = useMyCoopProfileMenu();

    const currentPage = useCurrentEpiPage();

    const menuPages = pages;

    const { data: isEligibleForBonus } = useEligibleForBonus(
        !!appConfig.coopUserSettings.isMedmeraCustomer,
    );

    const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        ga4NavigationTracking.profileMenu.click(e.currentTarget.innerText, e.currentTarget.href);
    };

    const handleLogoutLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        ga4UserTracking.logout();
        handleLinkClick(e);
    };

    const handleListItemClick = (
        e: React.MouseEvent<HTMLAnchorElement>,
        item: MyCoopProfileMenuItem,
    ) => {
        props.handleListItemClick?.(e);
        ga4NavigationTracking.profileMenuWalletInteractionTrigger.click(
            getParentName(pages, item.id) ?? e.currentTarget.innerText,
            getParentName(pages, item.id) === null ? undefined : e.currentTarget.innerText,
            'profile menu',
            e.currentTarget.innerText,
            e.currentTarget.href,
        );
    };

    const handleItemOpenClick = (item: MyCoopProfileMenuItem) => {
        ga4NavigationTracking.profileMenuWalletInteractionTrigger.open.level1Open(
            item.name,
            'profile menu',
        );
    };

    const handleItemCloseClick = (item: MyCoopProfileMenuItem) => {
        ga4NavigationTracking.profileMenuWalletInteractionTrigger.close.level1Close(
            item.name,
            'profile menu',
        );
    };

    useEffect(() => {
        ga4NavigationTracking.profileMenu.open();
        return () => {
            ga4NavigationTracking.profileMenu.close();
        };
    }, []);

    return (
        <>
            <MenuHeaderBonusSystem
                isEligibleForBonus={isEligibleForBonus}
                myBonusOverviewPageUrl={appConfig.coopSettings.myCoop.myBonusOverviewPageUrl}
                handleLinkClick={handleLinkClick}
            />
            <div className={styles.Content}>
                <FullOverlayLoader isLoading={menuIsLoading}>
                    {menuIsSuccess && (
                        <>
                            {menuPages !== undefined && (
                                <ProfileMenuList
                                    pages={menuPages}
                                    activePageId={currentPage?.data?.contentLink?.id}
                                    onItemClick={handleListItemClick}
                                    onItemOpen={handleItemOpenClick}
                                    onItemClose={handleItemCloseClick}
                                />
                            )}
                            <div className={styles.Footer}>
                                <Button theme="light" asChild>
                                    <a
                                        href={appConfig.coopSettings.logoutUrl}
                                        onClick={handleLogoutLinkClick}
                                    >
                                        Logga ut
                                    </a>
                                </Button>
                            </div>
                        </>
                    )}
                </FullOverlayLoader>
            </div>
        </>
    );
};

export default MyCoopProfileMenu;
