import { useElementEvent } from '@coop/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'underscore';

import type {
    CoopContentPageSize,
    CoopLayoutSize,
    SwiperBreakpointsCollection,
} from '../../../utility/coopSwiperBreakpoints';
import {
    CoopSwiperBreakpoints,
    getGridBreakpointRanges,
} from '../../../utility/coopSwiperBreakpoints';
import { useAppSelector } from '../../common/hooks/useThunkDispatch';

export const useBreakpoints = (
    breakpointAreaName: keyof SwiperBreakpointsCollection,
    hasSideBar: boolean,
    contentPageSize: CoopContentPageSize,
    layoutSize: CoopLayoutSize,
) => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleResize = useCallback(
        _.throttle(() => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }, 100),
        [],
    );

    useElementEvent(window, 'resize', handleResize, true);

    const breakpointArray = useMemo(() => {
        const breakpoints = CoopSwiperBreakpoints.getBreakpointsFor(
            breakpointAreaName,
            hasSideBar,
            contentPageSize,
            layoutSize,
        );
        return breakpoints;
    }, [breakpointAreaName, hasSideBar, contentPageSize, layoutSize]);

    const breakpointRanges = useMemo(() => {
        const ranges = getGridBreakpointRanges(breakpointArray);
        return ranges;
    }, [breakpointArray]);

    const getCellsInRow = useCallback(
        (width: number) => {
            const range = breakpointRanges.find((x) => width >= x.from && width <= x.to);
            if (!range) {
                return 1;
            }
            return Math.round(range?.cellsInRow);
        },
        [breakpointRanges],
    );

    const [cellsInRow, setCellsInRow] = useState(getCellsInRow(windowSize.width));

    useEffect(() => {
        const inRow = getCellsInRow(windowSize.width);
        setCellsInRow(inRow);
    }, [windowSize.width, getCellsInRow]);

    return {
        breakpoints: breakpointArray,
        cellsInRow,
    };
};

export const useBreakpointsSidebar = (breakpointAreaName: keyof SwiperBreakpointsCollection) => {
    const hasSideBar = useAppSelector((state) => state.ui.shared.hasSideBar);
    const layoutSize = useAppSelector((state) => state.ui.shared.layoutSize);
    const contentPageSize = useAppSelector((state) => state.ui.shared.contentPageSize);

    const result = useBreakpoints(breakpointAreaName, hasSideBar, contentPageSize, layoutSize);
    return result;
};
