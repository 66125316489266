import { useQuery } from '@tanstack/react-query';

import loyaltyFlow from '../../../common/flow/loyaltyFlow';
import { pointsQueryKeys } from '../pointsQueryKeys';

export const useEligibleForBonus = (enabled: boolean = true) => {
    const query = useQuery({
        queryKey: pointsQueryKeys.eliableForBonus,
        queryFn: loyaltyFlow.getEligibleForBonus,
        retry: 1,
        enabled,
        staleTime: 1000 * 60 * 5, // 5 minutes
    });

    return query;
};
