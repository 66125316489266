import type { FC } from 'react';
import * as React from 'react';
import { useRef } from 'react';

import DelayedA11yAnnouncer from '../../../common/components/DelayedA11yAnnouncer/DelayedA11yAnnouncer';
import { useSearchOverlayGA4ActionTracking } from '../../../common/tracking/hooks/useSearchOverlayGA4Tracking';
import { useSearchStateContext } from '../../../search/contexts/searchStateContext';
import type { GlobalSearchResults } from '../../../search/models/models';
import { getFullSearchResultUrlWithOrder } from '../../../search/utils/searchResultUtils';
import SearchOverlay from '../SearchOverlay';

export const GlobalSearchOverlayContent = React.lazy(
    () =>
        import('../../../search/components/GlobalSearchOverlayContent/GlobalSearchOverlayContent'),
);

interface GlobalSearchOverlayProps {
    performFullSearch?: (newQuery: string, originalQuery?: string) => void;
    shortcutLinks: SearchOverlayShortcut[];
}

const GlobalSearchOverlay: FC<React.PropsWithChildren<GlobalSearchOverlayProps>> = (props) => {
    const {
        query,
        close,
        isLoading,
        result,
        searchFromPage,
        expectedSearchResultType,
        a11yResultAnnounceMessage,
    } = useSearchStateContext<GlobalSearchResults>();
    const searchRef = useRef<HTMLInputElement>(null);

    const {
        trackAbortAction,
        trackCloseClickAction,
        trackListObjectClick,
        trackEnterAction,
        trackSeeAllResultsClickAction,
        trackLabelClick,
        trackAddToCartClickAction,
        trackRemoveFromCartClickAction,
    } = useSearchOverlayGA4ActionTracking(query, result?.queryUsed, searchFromPage, isLoading);

    const handleOutsideClick = () => {
        trackAbortAction();
    };

    const handleClearClick = () => {
        trackCloseClickAction();
    };

    const handleAllResultsClick = () => {
        trackSeeAllResultsClickAction();
    };

    const handleItemClick = () => {
        trackListObjectClick(undefined, undefined, undefined); // in global search event we dont care about positioning or names of the items, its relevant only for ecommerce page
    };

    const handleOtherResultTypeClick = () => {
        trackLabelClick();
    };

    const handleLabelClick = () => {
        trackLabelClick();
    };

    const navigateToGlobalSearchResultsPage = () => {
        trackEnterAction();

        let realQuery = isLoading ? query : result?.queryUsed;
        if (!realQuery) {
            realQuery = '';
        }
        if (props.performFullSearch) {
            close();
            props.performFullSearch(realQuery, query);
            return;
        }

        const viewAllResultsUrl = getFullSearchResultUrlWithOrder(
            realQuery,
            query,
            expectedSearchResultType,
        ).path;

        window.location.href = viewAllResultsUrl;
    };

    const onResultScroll = React.useCallback(() => searchRef.current?.blur(), [searchRef]);

    return (
        <SearchOverlay
            submitLabel="Sök på Coop"
            onEnterPress={navigateToGlobalSearchResultsPage}
            onOutsideClick={handleOutsideClick}
            onClearClick={handleClearClick}
            ref={searchRef}
            inputPlaceholder="Sök recept, varor, butiker och artiklar"
            inputDescription="Förslag visas nedan"
            announcerSlot={
                <DelayedA11yAnnouncer
                    a11yMessage={a11yResultAnnounceMessage}
                    name="GlobalSearchResultsAnnouncer"
                    delayFirstMessage
                />
            }
        >
            <GlobalSearchOverlayContent
                shortcutLinks={props.shortcutLinks}
                onAllResultsClick={handleAllResultsClick}
                onAllResultsForTypeClick={handleAllResultsClick}
                onOtherResultTypeClick={handleOtherResultTypeClick}
                onProductAddToCartClick={trackAddToCartClickAction}
                onProductRemoveFromCartClick={trackRemoveFromCartClickAction}
                onItemClick={handleItemClick}
                onLabelClick={handleLabelClick}
                onScroll={onResultScroll}
                useMaxHeightCalculation
            />
        </SearchOverlay>
    );
};

export default GlobalSearchOverlay;
