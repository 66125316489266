import classNames from 'classnames';

import ComponentPortals from '../../molecules/ComponentPortals';

interface StickyBottomProps {
    className?: string;
    usePortal?: boolean;
    children: React.ReactNode;
}

const StickyBottom = (props: StickyBottomProps) => {
    if (props.usePortal) {
        return <ComponentPortals cmpName="StickyBottom">{props.children}</ComponentPortals>;
    }

    return (
        <aside aria-label="Noteringar" className={classNames('StickyBottom', props.className)}>
            {props.children}
        </aside>
    );
};

export default StickyBottom;
