import { OverlayLoader } from '@coop/components';
import { lazy, Suspense } from 'react';

import { useCncFlyInState } from '../../hooks/useCncFlyInState';
import { FlyIn } from '../atoms/Modal';

const CncStepSwitcher = lazy(() => import('../../../cnc/components/CncStepSwitcher'));

const CncFlyIn = () => {
    const { isOpen, close, afterClose } = useCncFlyInState();

    return (
        <FlyIn isOpen={isOpen} close={close} afterClose={afterClose} additionalClasses="u-textLeft">
            <Suspense fallback={<OverlayLoader isLoading />}>
                <CncStepSwitcher />
            </Suspense>
        </FlyIn>
    );
};

export default CncFlyIn;
