import { appConfig } from '../../../appConfig';
import { useEpiPageByContentId } from '../../episerver/components/hooks/useCurrentEpiPage';
import type { EpiMyCoopSettingsPageType } from '../../episerver/components/pages/EpiMyCoopSettingsPage/epiMyCoopSettingsPageType';

export const useMyCoopSettingsPageQuery = (
    selectedFields?: (keyof EpiMyCoopSettingsPageType)[] | undefined,
) =>
    useEpiPageByContentId<EpiMyCoopSettingsPageType>(
        appConfig.coopSettings.myCoop.myCoopSettingsPageContentId,
        {
            depth: 1,
            select: selectedFields,
        },
        { gcTime: 1000 * 60 * 5 },
    );
