import { pick } from 'lodash-es';

type AllOptionalKeys<T> = { [K in keyof T]-?: undefined extends T[K] ? K : never }[keyof T];
type AllNonOptionalKeys<T> = { [K in keyof T]-?: undefined extends T[K] ? never : K }[keyof T];

type OptionalToMaybeUndefined<T> = { [K in AllOptionalKeys<T>]: T[K] | undefined } & {
    [K in AllNonOptionalKeys<T>]: T[K];
};

export const splitCustomObjectFromIntersection = <TCustom extends {}, TResult extends {}>(
    whole: TCustom & TResult,
    custom: OptionalToMaybeUndefined<TCustom>,
) => {
    const keysNotFromCustomProps = Object.keys(whole).filter(
        (key) => !Object.keys(custom).includes(key),
    );

    const rest = pick(whole, keysNotFromCustomProps) as TResult;
    return { custom, rest };
};
