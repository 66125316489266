/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import React from 'react';
import type { TextAreaProps, TextFieldProps } from 'react-aria-components';
import {
    FieldError as AriaFieldError,
    Input as AriaInput,
    Label as AriaLabel,
    Text as AriaText,
    TextArea as AriaTextArea,
    TextField as AriaTextField,
} from 'react-aria-components';

import styles from './TextField.module.scss';

const TextFieldRoot = (props: TextFieldProps) => (
    <AriaTextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        className={classNames(props.className, styles.Container)}
    >
        {props.children}
    </AriaTextField>
);

const TextFieldTopContainer = (props: { children: React.ReactNode }) => (
    <div className={styles.TopContainer}>{props.children}</div>
);

const TextFieldInputContainer = (props: { children: React.ReactNode }) => (
    <div className={styles.InputContainer}>{props.children}</div>
);

const TextFieldBottomContainer = (props: { children: React.ReactNode }) => (
    <div className={styles.BottomContainer}>{props.children}</div>
);

const TextFieldLabel = (props: {
    label?: string;
    isRequired?: boolean;
    necessityIndicator?: 'label' | 'none';
}) => {
    let indicator = props.necessityIndicator;
    if (!props.label) {
        return null;
    }

    // This case is for backwards compatibility. If the field is required, but the indicator is not set, we default to 'label'.
    // This essentially replaces old * indicator with (obligatoriskt) text if the indicator is not yet set.
    // This is to avoid breaking changes in the UI after new TextInput is deployed.
    if (props.isRequired && indicator === undefined) {
        indicator = 'label';
    }

    if (!indicator || indicator === 'none') {
        return <AriaLabel className={styles.Label}>{props.label}</AriaLabel>;
    }

    return (
        <AriaLabel className={classNames(styles.Label)}>
            {props.label}
            {props.isRequired ? (
                // Required is dealt with aria-required in the input. tehre is no ARIA for optional fields, so they must be part of the label, available for screenreaders.
                <span aria-hidden> (obligatoriskt)</span>
            ) : (
                <span> (frivilligt)</span>
            )}
        </AriaLabel>
    );
};

const TextFieldHelperText = (props: { helperText?: string; disabledReason?: React.ReactNode }) =>
    (props.helperText || props.disabledReason) && (
        <AriaText slot="description" className={styles.HelperText}>
            {[props.helperText, props.disabledReason].filter(Boolean).join('. ')}
        </AriaText>
    );

const TextFieldValidationError = (props: { validationText?: string }) =>
    props.validationText && (
        <AriaFieldError className={styles.ValidationText}>{props.validationText}</AriaFieldError>
    );

const TextFieldPostfix = (props: { children: React.ReactNode }) =>
    props.children && <div className={styles.PostfixSlot}>{props.children}</div>;

const TextFieldPrefix = (props: { children: React.ReactNode }) =>
    props.children && <div className={styles.PrefixSlot}>{props.children}</div>;

const TextFieldInput = React.forwardRef<HTMLInputElement, {}>((_props, ref) => (
    <AriaInput
        ref={ref}
        className={({ isInvalid }) => classNames(styles.Input, isInvalid && styles['is-invalid'])}
    />
));

const TextFieldTextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => (
    <AriaTextArea
        ref={ref}
        {...props}
        className={({ isInvalid }) =>
            classNames(props.className, styles.TextArea, isInvalid && styles['is-invalid'])
        }
    />
));

const TextField = {
    Root: TextFieldRoot,
    TopContainer: TextFieldTopContainer,
    InputContainer: TextFieldInputContainer,
    BottomContainer: TextFieldBottomContainer,
    Label: TextFieldLabel,
    HelperText: TextFieldHelperText,
    ValidationError: TextFieldValidationError,
    Prefix: TextFieldPrefix,
    Postfix: TextFieldPostfix,
    Input: TextFieldInput,
    TextArea: TextFieldTextArea,
};

export default TextField;
