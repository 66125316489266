import type { FC } from 'react';

import ComponentPortals from '../../../common/components/molecules/ComponentPortals';
import type { USPNoticeType } from '../USPNotices';
import USPNotices from '../USPNotices';
import HeaderNoticesContainer from './HeaderNoticesContainer';

interface HeaderNoticesDOMProps {
    uspNotices: USPNoticeType[];
}

const HeaderNoticesPortal: FC<React.PropsWithChildren<unknown>> = (props) => {
    return (
        <ComponentPortals
            cmpName="HeaderNotices"
            render={(domProps: HeaderNoticesDOMProps) => (
                <HeaderNoticesContainer>
                    {props.children}
                    <USPNotices data={domProps.uspNotices} />
                </HeaderNoticesContainer>
            )}
        />
    );
};

export default HeaderNoticesPortal;
