import { type FC, useRef } from 'react';

import RawHtml from '../../../common/components/atoms/RawHtml';
import { useDocumentPropertyHeightSetter } from '../../../common/hooks/useDocumentPropertyHeightSetter';
import Alert from './Alert';
import type { HeaderAlert } from './HeaderAlerts.types';
import { isAlertLevelSupported } from './HeaderAlerts.utils';

interface HeaderAlertsProps {
    alerts: HeaderAlert[];
}

const HeaderAlerts: FC<React.PropsWithChildren<HeaderAlertsProps>> = (props) => {
    const ref = useRef<HTMLDivElement>(null);
    useDocumentPropertyHeightSetter('--headerAlertsContainerHeight', ref);

    if (!props.alerts?.length) return null;
    return (
        <div ref={ref} className="HeaderAlerts">
            {props.alerts.map((alert) => (
                <Alert
                    key={alert.id}
                    id={alert.id}
                    level={isAlertLevelSupported(alert.level) ? alert.level : undefined}
                    details={alert.details && <RawHtml html={alert.details} />}
                >
                    <span className="u-textWeightBold">
                        <RawHtml html={alert.heading} />
                    </span>
                </Alert>
            ))}
        </div>
    );
};

export default HeaderAlerts;
