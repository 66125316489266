/* eslint-disable no-param-reassign */

import { appConfig } from '../../../../appConfig';
import BaseFooter from './BaseFooter';
import FooterMobileApps from './FooterMobileApps';

const Footer = (props: { footer: FrontendFooter }) => {
    const { footer } = props;

    const IsShoppingForMunicipal = appConfig.coopUserSettings.isShoppingForMunicipal;

    if (IsShoppingForMunicipal) {
        return null;
    }

    return (
        <BaseFooter footer={props.footer}>
            {footer.footerShowDownloadApp && (
                <FooterMobileApps
                    downloadAppAndroidUrl={footer.downloadAppAndroidUrl}
                    downloadAppIOSUrl={footer.downloadAppIOSUrl}
                    downloadAppQRImage={footer.downloadAppQRImage}
                    downloadAppQRImageAltText={footer.downloadAppQRImageAltText}
                />
            )}
        </BaseFooter>
    );
};

export default Footer;
