/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import type { PropsWithChildren } from 'react';
import * as React from 'react';
import { useEffect, useId, useImperativeHandle, useRef } from 'react';
import { VisuallyHidden } from 'react-aria';

import styles from './Search.module.scss';
import type { SearchSize } from './Search.types';
import SearchActions from './SearchActions';
import SearchContent from './SearchContent';
import SearchInputSlot from './SearchInputSlot';
import SearchRoot from './SearchRoot';

export interface SearchProps {
    className?: string;
    inputText?: string;
    defaultValue?: string;
    inputPlaceholder: string;
    noBorderRadius?: boolean;
    focusOnMount?: boolean;
    size: SearchSize;
    hide?: boolean;
    setInputText?: (newText: string) => void;
    onInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onReset?: () => void;
    onInputClick?: () => void;
    onInputFocus?: () => void;
    onInputBlur?: () => void;
    onEnterPress?: () => void;
    onEscapePress?: () => void;
    'aria-haspopup'?: React.ComponentPropsWithoutRef<'input'>['aria-haspopup'];
    'aria-label'?: React.ComponentPropsWithoutRef<'input'>['aria-label'];
    description?: string;
    submitLabel?: string;
}

const Search = React.forwardRef<HTMLInputElement, PropsWithChildren<SearchProps>>(
    (props, inputRef) => {
        const localRef = useRef<HTMLInputElement>(null);
        useImperativeHandle(inputRef, () => localRef.current as HTMLInputElement);

        const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.currentTarget;
            props.setInputText?.(value);
            props.onInputChange?.(e);
        };

        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter' && props.onEnterPress) {
                localRef.current?.blur();
                props.onEnterPress();
            }

            if (e.key === 'Escape' && props.onEscapePress) {
                localRef.current?.blur();
                props.onEscapePress();
            }
        };

        useEffect(() => {
            if (props.focusOnMount) {
                localRef.current?.focus();
            }
        }, [props.focusOnMount, localRef]);

        const descriptionId = useId();

        return (
            <SearchRoot
                size={props.size}
                hide={props.hide}
                className={classNames(props.inputText && styles['has-value'], props.className)}
            >
                <SearchContent>
                    {props.description && (
                        <VisuallyHidden>
                            <span id={descriptionId}>{props.description}</span>
                        </VisuallyHidden>
                    )}
                    <SearchInputSlot
                        ref={localRef}
                        type="search"
                        enterKeyHint="search"
                        defaultValue={props.defaultValue}
                        placeholder={props.inputPlaceholder}
                        onChange={handleInputChange}
                        value={props.inputText}
                        onFocus={props.onInputFocus}
                        onBlur={props.onInputBlur}
                        onKeyDown={handleKeyDown}
                        onClick={props.onInputClick}
                        aria-haspopup={props['aria-haspopup']}
                        aria-label={props.inputPlaceholder}
                        aria-describedby={props.description && descriptionId}
                    />
                    <SearchActions
                        submitLabel={props.submitLabel}
                        inputValue={props.inputText}
                        onEnterPress={props.onEnterPress}
                        clearButtonProps={{ onClick: props.onReset }}
                    />
                </SearchContent>

                {props.children}
            </SearchRoot>
        );
    },
);

export default Search;
