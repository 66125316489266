export const pointsQueryKeys = {
    eliableForBonus: ['eliableForBonus'],
    expiringPoints: ['expiringPoints'],
    expiringPointsByNumberMonths: (months: number) => [...pointsQueryKeys.expiringPoints, months],
    allOffers: ['allPointOffers'],

    pointBalanceByMonth: (month: number) => ['pointBalanceByMonth', month],
    previousMonthsBonus: ['previousMonthsBonus'],
    householdBalance: ['householdPointBalance'],
    householdMonetaryBalance: ['householdMonetaryBalance'],
    pointsMatrixStructureLevel: ['pointsMatrixStructureLevel'],
    member20PointsSummary: ['pointsSummary'],
    member20PointsSummaryYearly: ['pointsSummaryYearly'],
};

export const searchedPointOffer = {
    all: ['searchedPointOffers'],
    byIds: (ids: number[]) => [...searchedPointOffer.all, ids],
};

export const offerPropositionHistory = {
    all: ['offerPropositionHistory'],
    byRedeemed: (includedRedeemed: boolean) => [...offerPropositionHistory.all, includedRedeemed],
    byDate: (from: Date, to: Date, page: number, pageSize: number) => [
        ...offerPropositionHistory.all,
        from,
        to,
        page,
        pageSize,
    ],
};

export const vklVoucherDetails = {
    all: ['vklVoucherDetails'],
    byTransactionGuid: (guid: string) => [...vklVoucherDetails.all, guid],
};
