import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { appConfig } from '../../../../appConfig';
import {
    gdprAddEventListener,
    GdprCookiePurposes,
    gdprRemoveEventListener,
    hasAcceptedGdprPurpose,
} from '../../../../utility/gdpr';
import useHtmlScriptParser from '../../../common/hooks/useHtmlScriptParser';
import styles from './Footer.module.less';

const FooterLogos = (props: Pick<FrontendFooter, 'logos'>) => {
    const [consentPurposeAccepted, setConsentPurposeAccepted] = useState(false);

    const scriptsContainerRef = useHtmlScriptParser(
        '<script type="text/plain" src="https://cert.tryggehandel.net/js/script.js?id=a45e1529-7d52-45a5-8e2f-c79cddb2f92b" />',
        consentPurposeAccepted,
    );

    useEffect(() => {
        const checkPurpose = () => {
            setConsentPurposeAccepted(hasAcceptedGdprPurpose(GdprCookiePurposes.Marketing));
        };

        gdprAddEventListener('consent', checkPurpose);

        return () => gdprRemoveEventListener('consent', checkPurpose);
    }, []);

    return (
        <div className="u-textCenter">
            <p>
                <a href={appConfig.coopSettings.ecommerce.page?.url}>
                    <b>Handla på coop.se</b>
                </a>
            </p>
            <div className={styles.FooterShopOnline}>
                {props.logos.map((logo) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div
                        className={styles.FooterShopOnlineItem}
                        key={`${logo.url}_${logo.imageAltText}_${logo.imageHeight}_${logo.imageSrc}`}
                    >
                        {logo.url ? (
                            <a href={logo.url} target="_blank" rel="noreferrer">
                                <img
                                    height={logo.imageHeight}
                                    alt={logo.imageAltText}
                                    src={logo.imageSrc}
                                    loading="lazy"
                                />
                            </a>
                        ) : (
                            <img
                                height={logo.imageHeight}
                                alt={logo.imageAltText}
                                src={logo.imageSrc}
                                loading="lazy"
                            />
                        )}
                    </div>
                ))}

                <div
                    className={classNames(
                        styles.FooterShopOnlineItem,
                        'teh-certificate', // this class is important since Trygghandel script is looking for it
                    )}
                />
                <div style={{ display: 'none' }} ref={scriptsContainerRef} />
            </div>
        </div>
    );
};

export default FooterLogos;
