import type { FC, ReactNode } from 'react';
import { useState } from 'react';

import NoticeRibbon from '../../../common/components/atoms/NoticeRibbon';
import persistance from '../../../common/persistance/persistance';
import type { AlertLevel } from './HeaderAlerts.types';
import { getNoticeTypeForAlertLevel } from './HeaderAlerts.utils';

interface AlertProps {
    id: string;
    level?: AlertLevel;
    details: ReactNode | string;
    children: ReactNode;
}

const Alert: FC<React.PropsWithChildren<AlertProps>> = (props) => {
    const isDismissed = persistance.alertsDismissed.isDismissed(props.id);
    const [show, setShow] = useState(!isDismissed);

    const handleCloseClick = () => {
        persistance.alertsDismissed.dismiss(props.id);
        setShow(false);
    };

    return (
        <NoticeRibbon
            show={show}
            animated={false}
            handleCloseClick={handleCloseClick}
            blockClose={props.level === 1} // the VERY important messages can't be closed
            type={getNoticeTypeForAlertLevel(props.level)}
            expandableContent={props.details}
        >
            {props.children}
        </NoticeRibbon>
    );
};

export default Alert;
