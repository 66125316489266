import type { AxiosInstance } from 'axios';

import { appConfig } from '../../../../appConfig';
import axiosClientFactory from './clientFactory';
import type { CustomErrorInterceptor } from './localApiAxiosErrorInterceptors';

export type ApiLoyaltyException = {
    errorCode: number;
    status: string;
};
export type ApiLoyaltyAutoCheckException = {
    detail: string;
    status: number;
    title: string;
};
export type ApiLoyaltyMember20Exception = {
    title: string;
    status: number;
    detail: string;
};
export type ApiHouseholdMemberException = {
    title: string;
    status: number;
    detail: string;
    error: string;
};

const getDirectLoyaltyAxiosClient = <TApiError = unknown>(
    accessToken: string,
    errorInterceptors?: CustomErrorInterceptor<TApiError>[],
): AxiosInstance => {
    const client = axiosClientFactory.createDirectApiClient(
        {
            baseURL: `${appConfig.coopSettings.serviceAccess.extApimUrl}loyalty/account`,
            params: {
                'api-version': 'v1',
            },
            headers: {
                Accept: 'application/json',
                'Ocp-Apim-Trace': 'true',
                'Ocp-Apim-Subscription-Key':
                    appConfig.coopSettings.serviceAccess.extApimSubscriptionKey,
                Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
            },
        },
        errorInterceptors,
    );
    return client;
};

export default getDirectLoyaltyAxiosClient;
