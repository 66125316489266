/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import * as React from 'react';

import styles from './Search.module.scss';
import type { SearchBarOptions } from './Search.types';
import { getClassForSize } from './Search.utils';

interface SearchProps extends SearchBarOptions {
    className?: string;
}

const SearchRoot = React.forwardRef<HTMLDivElement, React.PropsWithChildren<SearchProps>>(
    (props, ref) => {
        return (
            <div
                ref={ref}
                className={classNames(
                    styles.Search,
                    props.hide && styles['Search--hidden'],
                    getClassForSize(props.size),
                    props.className,
                )}
                data-test="search"
            >
                {props.children}
            </div>
        );
    },
);

export default SearchRoot;
