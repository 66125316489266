import * as React from 'react';
import { VisuallyHidden } from 'react-aria';

import type { Icon } from '../Icon';
import { AccessibleIcon } from '../Icon';
import Button from './Button';
import type { ButtonProps } from './Button.types';

type IconButtonProps = {
    icon: Parameters<typeof Icon>[0]['icon'];
    label: string;
} & Omit<ButtonProps, 'asIcon' | 'asChild'>;

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
    ({ icon, label, children, ...buttonProps }, ref) => {
        return (
            <Button
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...buttonProps}
                asIcon
                ref={ref}
                loadingText={<VisuallyHidden>{buttonProps.loadingText || 'Laddar'}</VisuallyHidden>}
            >
                <AccessibleIcon label={label} icon={icon} />
                {children}
            </Button>
        );
    },
);

export default IconButton;
